import {Button, Typography} from '@mui/material'
import React from 'react'

const SUBMIT_COLOR = {primary: 'primary', secondary: 'secondary', gradient: 'activated'}
const SUBMIT_VARIANT = {outlined: 'outlined', contained: 'contained'}

const SubmitButton = ({text, variant, color, onButtonClick, iconBefore, iconAfter, disabled=false }) => {
    const calculatedColor = color === 'gradient' ? '' : color;
    const calculatedVariant = color === 'gradient' ? 'activated' : variant;

    return (
        <Button variant={calculatedVariant}
                color={calculatedColor}
                onClick={onButtonClick}
                size="large"
                sx={{width: "100%"}}
                disabled={disabled}>
            <Typography variant="button" component="div" display="flex" gap="5px" alignItems="center">
                {iconBefore} {text} {iconAfter}
            </Typography>
        </Button>
    )
}

export default SubmitButton