import { TextField } from '@mui/material';

const VerificationCodeInput = ({ handleCode, handleKey, char, index, maxLength }) => {
    const handleChange = (ev) => {
        const pattern = /^\d*$/;
        const target = ev.currentTarget;
        const isValidChar = pattern.test(target.value);

        if (!isValidChar) return;

        handleCode(ev, target.value, index);
    };
    const handleFocus = (ev) => {
        ev.currentTarget.select();
    };

    return (
        <TextField
            id={`verification-input-${index}`}
            type="text"
            color="secondary"
            autoComplete="one-time-code"
            onChange={handleChange}
            onKeyDown={(ev) => handleKey(ev, index)}
            value={char}
            onFocus={handleFocus}
            inputProps={{ maxLength: 1, inputMode: 'numeric' }}
        />
    );
};

export default VerificationCodeInput;