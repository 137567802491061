import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {ROLES} from "../App";
import {theme} from "../theme";

import Navbar from "../components/ui/navbar";

import {Alert, ThemeProvider, Typography} from "@mui/material";
import Lobby from "../components/ui/lobby";
import Game from "./game";
import Box from "@mui/material/Box";
import {ampli} from "../ampli";

const Dashboard = ({socket, socketConnected, deferredPrompt}) => {
    const {user, role} = useSelector((state) => state.auth);

    const [isPlaying, setIsPlaying] = useState(false);
    const [game, setGame] = useState(null);
    const [messageStatus, setMessageStatus] = useState("");

    useEffect(() => {
        socket.on('on_room_join', (data) => {
            // console.log("game :", data);
            setIsPlaying(true);
            setGame(data);
            ampli.gameStart({waitingTime: 0});
        });

        socket.on('error', data => {
            // console.log('error', data);
            setIsPlaying(false);
            showMessage(data.message, 5000);
        });

        if(!socketConnected && isPlaying) {
            setIsPlaying(false);
            showMessage("Vous avez été déconnecté de la partie", 5000);
        }

        return () => {
            socket.removeAllListeners('on_room_join');
            socket.removeAllListeners('error');
        };
    }, [socketConnected]);

    const handleStop = () => {
        socket.emit('leave_game');
        setIsPlaying(false);
    }

    const showMessage = (message, duration) => {
        setMessageStatus(message);
        setTimeout(() => {
            setMessageStatus("");
        }, duration);
    }

    return (
        <ThemeProvider theme={theme}>
            <Box height="100%" display="flex" flexDirection="column" className="contentWrapper">
                {isPlaying ? <Game socket={socket} handleStop={handleStop} game={game} setGame={setGame}/> : <Lobby socket={socket} socketConnected={socketConnected} deferredPrompt={deferredPrompt}/> }
                { messageStatus && <Alert severity="error"><Typography variant="caption">{messageStatus}</Typography></Alert> }
                {user && role === ROLES.User && !isPlaying ? <Navbar activeLink="dashboard"/>: ''}
            </Box>
        </ThemeProvider>
    );
};

export default Dashboard;