import React, {useEffect, useRef, useState} from "react";
import StyledAvatar from "../customAvatar";
import {useDispatch, useSelector} from "react-redux";
import {deleteMatch, getGames, getMessages, signalMatch, userHasAccount} from "../../services/match.service";
import {motion} from "framer-motion";
import {Alert, Button, Modal, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import LeftArrow from '../../assets/left-arrow.png';
import Dots from '../../assets/dots.png';
import AnswerInput from "../answerInput";
import {removeMatch, setLastMessageToRead, updateMatch} from "../../redux/match.slice";
import {formatGame} from '../../services/util';
const MatchChat = ({socket, match, setMatch}) => {
    const {user, token} = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [games, setGames] = useState([]);
    const [open, setOpen] = useState(false);
    const [matchHasAccount, setMatchHasAccount] = useState(false);

    const messagesEndRef = useRef(null)

    useEffect(() => {
        const fetchMessages = async () => {
            const data = await getMessages(match.id, token);
            setMessages(data || []);
        }

        const fetchGames = async () => {
            console.log("sss")
            const data = await getGames(match.id, token);
            setGames(data);
            console.log(data);
        }

        userHasAccount(match.userId, token).then((hasAcc) => {
            setMatchHasAccount(hasAcc);
        })

        // call the function
        fetchMessages().then(() => {
            console.log("scrooool");
            scrollToBottom();
        });

        fetchGames();
    }, [match.id]);

    useEffect(() => {
        const receiveMessageHandler = (data) => {
            const newMessage = { _id: data._id, content: data.lastMessage, sender: match.userId, match: data.matchId, receiver: user._id, isRead: data.isRead };

            setMessages((prevMessages) => [...prevMessages, newMessage]);
            scrollToBottom();
        };

        socket.on('receive_chat_message', receiveMessageHandler);

        return () => {
            socket.off('receive_chat_message', receiveMessageHandler);
        };
    }, [socket]);

    useEffect(() => {
        const unreadMessages = messages.filter((msg) => !msg.isRead && msg.receiver === user._id);

        // console.log('unread ?', unreadMessages);
        if (unreadMessages.length > 0) {
            // console.log("this user ??", unreadMessages)
            const unreadMessageIds = unreadMessages.map((msg) => msg._id);
            socket.emit('update_messages_status', { matchId: match.id, messagesIds: unreadMessageIds });

            // Update the messages state
            const updatedMessages = messages.map((msg) => {
                if (unreadMessageIds.includes(msg._id)) {
                    return {
                        ...msg,
                        isRead: true,
                    };
                }
                return msg;
            });
            // Dispatch the updated messages to the state
            setMessages(updatedMessages);

            dispatch(setLastMessageToRead(match.id));
        }
    }, [messages]);

    const sendMessage = (e) => {
        e.preventDefault();
        if (message !== '') {
            socket.emit('send_chat_message', { matchId: match.id, senderId: user._id, content: message });
            dispatch(updateMatch({matchId: match.id, lastMessage: message, lastMessageSender: true, isRead: false, timestamp: null}));
            setMessage('');

            const sentMessage = { content: message, sender: user._id, match: match.id, receiver: match.userId, isRead: false };
            setMessages((prevMessages) => [...prevMessages, sentMessage]);
            scrollToBottom();
        }
    };

    const scrollToBottom = () => {
        // console.log("scroll", messages)
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const handleGoBack = () => {
        setMatch(null);
    };

    const handleDeleteMatch = () => {
        deleteMatch(match.id, user._id, token).then((response) => {
            dispatch(removeMatch(match.id));
            setOpen(false);
            setMatch(null);
        })
    }

    const handleSignalMatch = () => {
        signalMatch(match.id, user._id, token).then((response) => {
            dispatch(removeMatch(match.id));
            setOpen(false);
            setMatch(null);
        })
    }

    return (
        <motion.div
            initial={{x: "100%"}}
            animate={{x: "0%"}}
            transition={{duration: 0.15, ease: "easeOut"}}
            exit={{x: "100%"}}
            className="matchChatContainer"
        >
            <Box id="chatmodal" onSubmit={sendMessage} className="chat" sx={{position: 'relative'}}>
                <Box className="matchChatHeader">
                    <img src={LeftArrow} onClick={handleGoBack} alt='go back' style={{width: '15px', height: 'auto'}}/>
                    <StyledAvatar src={`${match.url}`} sx={{ width: 40, height: 40 }} style={{margin: ' 0 10px 0 20px'}}></StyledAvatar>
                    <Typography variant='h5' fontWeight="bold">{match.name}</Typography>
                    {match.isNotAllowed ? '' : <img src={Dots} onClick={() => setOpen(true)} alt='go back' style={{width: '20px', height: 'auto', marginLeft: 'auto'}}/>
                    }
                </Box>

                <Box className="messagesColumn">
                    {games && games.map((game, i) => {
                        return formatGame(game, i, user, match.url);
                    })}

                    {messages && messages.map((msg, i) => (
                        <div className={`message ${msg.sender === user._id ? "me" : "other"}`} key={i}>
                            <Typography variant="body2" className="msgText">{msg.content}</Typography>
                        </div>
                    ))}
                    {!matchHasAccount ?
                        <Alert severity="warning"><Typography variant="caption">Attention, {match.name} n'a pas encore de compte</Typography></Alert>
                        : ''}
                    <div style={{minHeight: '40px', width:'10px'}}>
                    </div>
                    <div ref={messagesEndRef}>
                    </div>
                </Box>

                { match.isNotAllowed || !matchHasAccount ? ''
                    :
                    <Box className='sendMessageContainer' pb={4}>
                        <AnswerInput answer={message} handleSubmit={sendMessage} handleChange={(e) => setMessage(e.target.value)} disabled={!matchHasAccount}/>
                    </Box>
                }


                {/*<div className="sendMessageContainer">*/}
                {/*    <input*/}
                {/*        type="text"*/}
                {/*        placeholder='Message...'*/}
                {/*        onChange={(e) => setMessage(e.target.value)}*/}
                {/*        value={message}*/}
                {/*    />*/}
                {/*    <button type="submit" disabled={!message || !matchHasAccount}>*/}
                {/*    </button>*/}
                {/*</div>*/}

                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    container={() => document.getElementById('chatmodal')}
                >
                    {/*<m.div*/}
                    {/*    initial={{x: "100%"}}*/}
                    {/*    animate={{x: "0%"}}*/}
                    {/*    transition={{duration: 0.5, ease: "easeOut"}}*/}
                    {/*    exit={{y: "100%"}}*/}
                    {/*>*/}
                        <Box sx={{
                            position: 'absolute',
                            bottom: '2%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '80%',
                            bgcolor: 'background.paper',
                            borderRadius: '15px',
                            textAlign: 'center',
                            boxShadow: 12,
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                    {/*    <motion.box*/}
                    {/*        className='matchesChatModalAction'*/}
                    {/*        initial={{ y: "100%" }}*/}
                    {/*        animate={{ y: 0 }}*/}
                    {/*        transition={{ type: "spring", stiffness: 300, damping: 30 }}*/}
                    {/*    >*/}
                            <Button id="modal-modal-title" sx={{p: 2}} onClick={handleDeleteMatch} color="secondary">
                                Supprimer
                            </Button>
                            <Button id="modal-modal-description" sx={{p: 2}} onClick={handleSignalMatch} color="secondary">
                                Signaler
                            </Button>
                        {/*</motion.box>*/}
                    </Box>
                </Modal>
            </Box>
        </motion.div>
    );
};

export default MatchChat;