import React from "react";
import {Grid, TextField, Typography} from "@mui/material";

import Box from "@mui/material/Box";

import {FaInfoCircle} from 'react-icons/fa';
import SubmitButton from "../mui/submitButton";

const RegisterName = ({name, onNameChange, onButtonClick}) => {
    return (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
            <Grid container wrap='nowrap' direction="column" rowSpacing={3}>
                <Grid item>
                    <Typography variant="h4">Commence par nous donner ton prénom</Typography>
                </Grid>
                <Grid item width="100%" textAlign="center">
                    <TextField size="small" fullWidth={true} type="text"
                               variant="outlined" color="secondary"
                               value={name}
                               onChange={onNameChange}
                               inputProps={{ maxLength: 12 }}
                               InputProps={{
                                   inputProps: {
                                       style: { textAlign: "center" },
                                   }
                               }}/>
                </Grid>
            </Grid>

            <Grid container wrap='nowrap' direction="column" rowSpacing={3}>
                <Grid item>
                    <Box className="registerinfo">
                        <FaInfoCircle size={25} />
                        <Typography variant="caption">Il sera affiché sur ton profil. Tu pourras le modifier plus tard</Typography>
                    </Box>
                </Grid>
                <Grid item sx={{ width: '100%'}} mb={4}>
                    <SubmitButton text='Continuer' variant="contained" color="secondary" onButtonClick={onButtonClick} disabled={name === ''}/>
                </Grid>
            </Grid>
        </Box>

    );
}

export default RegisterName