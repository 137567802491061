import React from "react";
import {Button, Grid, Typography} from "@mui/material";

import Box from "@mui/material/Box";

import {FaInfoCircle} from 'react-icons/fa';
import AddPhotoIcon from '@mui/icons-material/AddAPhoto'
import UploadPicture from "../account/uploadPicture";

const RegisterPictures = ({pictures, imageObject, onPictureChange, onButtonClick}) => {
    return (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
            <Grid container wrap='nowrap' direction="column" rowSpacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4" fontWeight="500">Ajoute une photo</Typography>
                </Grid>
                <Grid item width="75%">
                    <UploadPicture imageObject={imageObject} onPictureChange={onPictureChange} />
                </Grid>
            </Grid>

            <Grid container wrap='nowrap' direction="column" rowSpacing={2} width='100%'>
                <Grid item>
                    <Box className="registerinfo">
                        <FaInfoCircle size={25} />
                        <Typography variant="caption">Pour l'instant tu ne peux ajouter qu'une photo</Typography>
                    </Box>
                </Grid>
                <Grid item sx={{ width: '100%'}} mb={4}>
                    <Button color="secondary" variant="contained" onClick={onButtonClick} sx={{ width: "100%" }} disabled={!imageObject}>
                        <Typography variant="h5" component="p">Continuer</Typography>
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default RegisterPictures