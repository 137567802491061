import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    targetDate: null,
    isOn: false
};

const countdownSlice = createSlice({
    name: "countdown",
    initialState: initialState,
    reducers: {
        setCountdown: (state, {payload}) => {
            state.targetDate = payload.targetDate;
            state.isOn = payload.isOn;
        },
    },
});

export const { setCountdown } = countdownSlice.actions;

export default countdownSlice.reducer;
