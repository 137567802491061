import {Chip, Grid, Typography} from "@mui/material";
import AnswerInput from "./answerInput";
import React from "react";

const BuildQuestionBox = ({suffix, theme, handleSubmit, answer, handleChange}) => {
    return (
        <form onSubmit={handleSubmit}>
            <Grid className="buildGrid" container height="100%" display="flex" padding={1} flexDirection="column" bgcolor="#FFFFFF" borderRadius="15px">
                <Grid item>
                    <Typography variant="body1">{suffix} {theme.prefix}</Typography>
                </Grid>
                <Grid item marginBottom={0.5}>
                    <Chip label={<Typography>{theme.logo} {theme.name}</Typography>} color="tertiary" size="small" />
                </Grid>
                <Grid item width="100%">
                    <AnswerInput answer={answer} handleSubmit={handleSubmit} handleChange={handleChange} maxLength={18}/>
                </Grid>
            </Grid>
        </form>
    );
};

export default BuildQuestionBox;