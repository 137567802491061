import React, {useEffect, useState} from "react";
import {Divider, Grid, Typography} from "@mui/material";
import Answer, {TYPES} from "../answer";
import GameHeader from "../ui/gameHeader";
import Box from "@mui/material/Box";
import GameExplanation from "../gameExplanation";
import GameExplanationImageBachelor from '../../assets/explanation_sentence_bachelor.svg';

// Composant
const SentenceEliminate = ({socket, data: {suggestions, question, responses, canEliminate}, initialDuration, players}) => {
    const [answer, setAnswer] = useState([]);
    const [duration, setDuration] = useState(initialDuration);
    const [eliminationDone, setEliminationDone] = useState(false);
    const [isBlinking, setIsBlinking] = useState(false);

    useEffect(() => {
        socket.on('final_results', handleFinalAnswers);

        return () => {
            socket.off('final_results', handleFinalAnswers);
        }
    }, []);

    useEffect(() => {
        if (answer.length === 2 && canEliminate) {
            socket.emit("game_response", { 'userIdsToKeep' : answer });
            setEliminationDone(true);
        }
    }, [answer]);

    useEffect(() => {
        setDuration(initialDuration);
    }, [canEliminate]);

    useEffect(() => {
        setDuration(null);
        if(eliminationDone) {
            setIsBlinking(true);
            const timer = setTimeout(() => {
                setIsBlinking(false);
            }, 2000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [eliminationDone]);

    const handleSelectResponse = (responseId) => {
        if(canEliminate) {
            if (answer.includes(responseId)) {
                // If the response is already selected, remove it from the answer
                setAnswer(answer.filter((id) => id !== responseId));
            } else if (answer.length < 2) {
                // If less than two responses are selected, add the response to the answer
                setAnswer([...answer, responseId]);
            }
        }
    };

    const handleFinalAnswers = (data) => {
        setAnswer(data.selectedUserIds);
        setEliminationDone(true);
    }

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <GameExplanation url={GameExplanationImageBachelor} />
            <GameHeader duration={duration} description={canEliminate ? "Sélectionne les 2 réponses que tu préfères !" : "Attends que les prétendants répondent"} title="Élimination" players={players} />

            <Grid container className="eliminate" flexDirection='column' justifyContent="flex-start" alignItems="center" gap={2} width="95%" margin="auto" wrap='nowrap' height="100%">
                <Grid item width="100%">
                    <Answer url={players.bachelor.url} sentence={question} name={players.bachelor.name} type={TYPES.Color}/>
                </Grid>

                <Grid container flexDirection={'column'} alignItems={"center"}>
                    <Divider>
                        <Typography textAlign="center">{canEliminate ? "Sélectionne tes 2 réponses préférées ! " : "Attends que les prétendants répondent"}</Typography>
                    </Divider>
                </Grid>

                {/* Grille avec les réponse du prétendant */}
                <Grid container direction={'column'} justifyContent={"space-around"} width={"100%"} height={'100%'} marginBottom={2}>
                    {responses.map((object, i) => {
                        const user = players.contenders.find(user => user.id === object.id);
                        return (<Grid item key={i} width="100%" onClick={(e)=> handleSelectResponse(object.id)}>
                            <Answer url={user?.url} type={answer.includes(object.id) ? TYPES.Active : eliminationDone ? TYPES.Eliminate : TYPES.Normal}
                                    sentence={!object.answer ? canEliminate ? "Pas de réponse" : "" : object.answer} name={user?.name} isLoading={!object.answer && !canEliminate} isBlinking={isBlinking}/>
                        </Grid>);
                    })}
                </Grid>
            </Grid>
        </Box>
    );
};

export default SentenceEliminate;