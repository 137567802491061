import React from 'react';
import { Link } from 'react-router-dom';
import {Link as MuiLink, Box} from '@mui/material';
import iconDashboard from '../../assets/icon_nav_dashboard.svg';
import iconMatches from '../../assets/icon_nav_matches.svg';
import iconAccount from '../../assets/icon_nav_account.svg';
import iconDashboardActive from '../../assets/icon_nav_dashboard_active.svg';
import iconMatchesActive from '../../assets/icon_nav_matches_active.svg';
import iconAccountActive from '../../assets/icon_nav_account_active.svg';

const Navbar = ({activeLink}) => {
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    return (
        <Box className='navbar' pb={isInStandaloneMode ? 2 : 0}>
            <MuiLink flex={1}
                     alignItems='center'
                     display="flex"
                     padding={1}
                     flexDirection="column"
                     component={Link} to="/app/dashboard">
                <img src={activeLink === "dashboard" ? iconDashboardActive : iconDashboard} alt="dashboard"/>
            </MuiLink>
            <MuiLink flex={1}
                     alignItems='center'
                     display="flex"
                     padding={1}
                     flexDirection="column"
                     component={Link} to="/app/matches">
                <img src={activeLink === "matches" ? iconMatchesActive : iconMatches} alt="matchs"/>
            </MuiLink>
            <MuiLink flex={1}
                     alignItems='center'
                     display="flex"
                     padding={1}
                     flexDirection="column"
                     component={Link} to="/app/acc">
                <img src={activeLink === "acc" ? iconAccountActive : iconAccount} alt="account"/>
            </MuiLink>
        </Box>
    );
};

export default Navbar;
