import React from "react";
import {Grid, InputAdornment, TextField, Typography} from "@mui/material";

import Box from "@mui/material/Box";
import SubmitButton from "../mui/submitButton";

const RegisterMore = ({bio, onBioChange, onButtonClick}) => {
    return (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
            <Grid container wrap='nowrap' direction="column" rowSpacing={3}>
                <Grid item>
                    {/*<Typography variant="h4">Tu veux donner plus d'informations à propos de toi ?</Typography>*/}
                    <Typography variant="h4">Ajoute une description amusante et qui se démarque</Typography>
                </Grid>
                <Grid item width="100%">
                    {/*<Typography variant="h6">Décris toi en quelques mots</Typography>*/}

                    <TextField size="small" fullWidth={true}
                               value={bio}
                               onChange={onBioChange}
                               color="secondary"
                               sx={{borderRadius: '10px'}}
                               multiline
                               rows={3}
                               inputProps={{
                                   maxLength: 250,
                               }}
                               InputProps={{
                                   style: {borderRadius: '10px'},
                                   endAdornment: (
                                       <InputAdornment position="end"
                                                       sx={{position: 'absolute', bottom: 16, right: 16}}>
                                           <Typography variant="caption">{bio.length}/250</Typography>
                                       </InputAdornment>
                                   ),
                               }}
                    />
                </Grid>
            </Grid>

            <Grid container wrap='nowrap' direction="column" rowSpacing={2}>
                {/*<Grid item>*/}
                {/*    <Box className="registerinfo">*/}
                {/*        <FaInfoCircle size={25} />*/}
                {/*        <Typography variant="caption">Il sera affiché sur ton profil. Tu pourras le modifier plus tard</Typography>*/}
                {/*    </Box>*/}
                {/*</Grid>*/}
                <Grid item sx={{ width: '100%'}} mb={4}>
                    <SubmitButton text='Terminer' variant="contained" color="secondary" onButtonClick={onButtonClick} />
                </Grid>
            </Grid>
        </Box>

    );
}

export default RegisterMore