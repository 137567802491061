import {useSelector} from "react-redux";
import Navbar from "../components/ui/navbar";
import {ROLES} from "../App";
import Box from "@mui/material/Box";
import React, {useState} from "react";
import StyledAvatar from "../components/customAvatar";
import {Chip, ThemeProvider, Typography} from "@mui/material";
import MatchChat from "../components/ui/matchChat";
import {AnimatePresence} from "framer-motion";
import Header from "../components/ui/header";
import {theme} from "../theme";
import { styled } from '@mui/material/styles';

const CustomChip = styled(Chip)(({ theme }) => ({
    '& .MuiChip-label': {fontSize: 8},
    '&.MuiChip-root': {
        height: theme.spacing(2)
    }
}));

const Matches = ({socket}) => {
    const {user, token, role} = useSelector((state) => state.auth);
    const matchedProfiles = useSelector((state) => state.match.matches);

    const [activeMatch, setActiveMatch] = useState(null);

    return (
        <ThemeProvider theme={theme}>
            <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between" position="relative" className="contentWrapper">
                <Header />

                <Typography variant="body1" sx={{flex: '0 0', pl: '1em', pb: '0.5em'}}>Messages</Typography>

                <Box width="100%" className="matchesContainer">
                {matchedProfiles && matchedProfiles.map((match, i) => (
                    <Box key={i} className='matchBox' onClick={() => setActiveMatch(match)}>
                        <Box sx={{position: 'relative'}}>
                            <StyledAvatar src={`${match.url}`} sx={{ width: 60, height: 60 }}></StyledAvatar>
                            {!match.isRead && !match.lastMessageSender && <Box className="matchNewMessageNotif"></Box> }
                        </Box>
                        <Box sx={{pl: '20px', minWidth: '0', flex: '1'}}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Typography variant="h6" fontWeight="bold">{match.name}</Typography>
                                {match.isRead && !match.lastMessageSender && <CustomChip label='À TON TOUR' color="secondary" size="small"/>}
                            </Box>
                            <Typography variant="body1" className="textOverflow" color="secondary">{match.lastMessage}</Typography>
                        </Box>
                    </Box>
                ))}
                </Box>


                <Box>
                    <AnimatePresence>
                        {activeMatch && <MatchChat key={activeMatch.id} socket={socket} match={activeMatch} setMatch={setActiveMatch}/> }
                    </AnimatePresence>
                </Box>

                {user && role === ROLES.User ? <Navbar activeLink="matches"/>: ''}
            </Box>
        </ThemeProvider>
    );
};

export default Matches;