import React, {useState} from 'react';
import {Box} from '@mui/material';
import VerificationCodeInput from "./verificationCodeInput";

const VerificationCodeInputs = ({ codeLength, onVerificationCodeComplete }) => {
    const emptyCode = Array(codeLength).fill('');
    const [code, setCode] = useState(emptyCode);

    const handleCode = (ev, value, index) => {
        const newCode = [...code];
        const remainingFields = codeLength - index;
        const newValue = value.length ? value.split('', remainingFields) : [''];
        const newValueSize = value.length ? value.length : 1;
        const target = ev.target;

        newCode.splice(index, newValueSize, ...newValue);
        setCode(newCode);

        if (value.length && value.length < codeLength && index !== codeLength - 1) {
            const nextInput = document.getElementById(`verification-input-${index + 1}`);
            if (nextInput) nextInput.focus();
        }

        if (value.length === codeLength) {
            target.blur();
        }

        if(newCode.filter(value => value !== '').join('').length === codeLength) {
            onVerificationCodeComplete(newCode.filter(value => value !== '').join(''));
        } else {
            onVerificationCodeComplete('');
        }
    };

    const handleKey = (ev, index) => {
        const target = ev.target;
        if (ev.key === 'Backspace' && target.value === '' && index) {
            const prevInput = document.getElementById(`verification-input-${index - 1}`);
            if (prevInput) prevInput.focus();
        }
        if (ev.key === 'ArrowLeft') {
            const prevInput = document.getElementById(`verification-input-${index - 1}`);
            if (prevInput) prevInput.focus();
        }
        if (ev.key === 'ArrowRight') {
            const nextInput = document.getElementById(`verification-input-${index + 1}`);
            if (nextInput) nextInput.focus();
        }
    };

    return (
        <Box display="flex" columnGap={1}>
            {code.map((char, index) => (
                <VerificationCodeInput
                    key={index}
                    handleCode={handleCode}
                    handleKey={handleKey}
                    char={char}
                    index={index}
                    maxLength={codeLength}
                />
            ))}
        </Box>
    );
};

export default VerificationCodeInputs;