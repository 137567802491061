import {configureStore} from "@reduxjs/toolkit";
import authReducer from './auth.slice'
import inviteReducer from './invite.slice'
import matchReducer from './match.slice'
import countdownReducer from "./countdown.slice";
import pwaReducer from './pwa.slice';
import registerReducer from './register.slice';

// import storage from 'redux-persist/lib/storage';
// import { persistReducer, persistStore } from 'redux-persist';
// import thunk from 'redux-thunk';

// const persistConfig = {
//     key: 'root',
//     storage,
// }

// const persistedReducer = persistReducer(persistConfig, registerReducer)

export const store = configureStore({
    reducer: {
        auth: authReducer,
        invite: inviteReducer,
        match: matchReducer,
        countdown: countdownReducer,
        pwa: pwaReducer
    },
    // middleware: [thunk]
})

// export const persistor = persistStore(store)