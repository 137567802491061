import { createSlice } from '@reduxjs/toolkit';

const inviteSlice = createSlice({
    name: 'invite',
    initialState: {
        inviteId: null,
        alreadyJoined: false
    },
    reducers: {
        setInviteId: (state, action) => {
            state.inviteId = action.payload;
        },
        joinInvite: (state, action) => {
            state.alreadyJoined = true;
        },
        leaveInvite: (state, action) => {
            state.inviteId = null;
            state.alreadyJoined = false;
        }
    },
});

export const { setInviteId, leaveInvite, joinInvite } = inviteSlice.actions;
export default inviteSlice.reducer;
