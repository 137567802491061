import React from "react";
import {Avatar, Box, Grid,Stack,Typography} from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { motion } from 'framer-motion';

const OnChainScore = ({name, url, classement, isLosing, isGood, score, maxRep}) => {

    const pourcentagevictoire = () => {
        return maxRep === 0 ? 0 : 10+(score/maxRep)*25;
    }

    return (
        // <motion.Box width="100%" height="100%">
        //     <motion.Grid
        //         container
        //         className="twoChoices"
        //         flexDirection="column"
        //         justifyContent="space-around"
        //         alignItems="center"
        //         height="100%"
        //         margin="auto"
        //         wrap="nowrap"
        //     >
                <Box width="100%" wrap="nowrap" sx={{position:'relative',filter:'drop-shadow(4px 4px 4px rgba(0,0,0,0.25))'}}>
                    {/* La box est faite du background avec score et checkbox la bulle du personnage avec classement est plus loing pour la mettre sur le background */}
                    {/* Debut de la bulle du score actuels qui se trouve derriere*/}
                    <Grid container justifyContent={"flex-end"} direction={"row"} width={"100%"} height={"90%"}
                          sx={{ top:"0px", left:"0px" ,position:'absolute'}}
                    >
                        {/* Item avec score */}
                        <Grid container gap={2} direction='row'alignItems={"center"} justifyContent={"flex-end"} height={"100%"} width={"60%"}
                              sx={{border:"1px solid rgba(0,0,0,0.5)", borderRadius:"2000px", backgroundColor : isLosing ? "#BBBBBB":"white"}} >
                            <Typography color={isLosing ? "white":"#B650FA"}sx={{fontWeight:"600"}}> {score}/{maxRep} </Typography>
                            <Typography/>
                        </Grid>
                        {/* Icone réussite ou non */}
                        <Grid item height={"100%"} sx={{aspectRatio:'1/1'}}>
                            {isGood === null ?
                                <Box width="100%" height={'100%'} sx={{border:"1px solid rgba(0,0,0,0.5)", borderRadius:"2000px", backgroundColor : isLosing ? "#BBBBBB":"white"}}></Box>
                                : isGood ?
                                    // TODO : Crée notre propre composants pour qu'il prenne la full width
                                    <Box width="100%" height={'100%'} sx={{border:"1px solid rgba(0,0,0,0.5)", borderRadius:"2000px",backgroundColor :"#B650FA"}}>
                                        <CheckCircleRoundedIcon  sx={{height:'100%',width:"100%",color:"#B650FA"}}/>
                                    </Box>
                                    :
                                    <Box width="100%" height={'100%'} sx={{border:"1px solid rgba(0,0,0,0.5)", borderRadius:"2000px",backgroundColor :"#B650FA"}}>
                                        <CancelRoundedIcon  sx={{height:'100%',width:"100%",color:"#B650FA"}}/>
                                    </Box>
                            }
                        </Grid>
                    </Grid>
                    {/* Barre de progression violette */}
                    <Box className="scoreAnimation"
                         sx={{width:`${pourcentagevictoire()}%` ,height:"10%", bottom:"0px", left:"50%" ,position:'absolute',
                             border:"1px solid rgba(0,0,0,0.5)", borderRadius:"2000px", backgroundColor : isLosing ? "#BBBBBB":"#B650FA"}}
                    >

                    </Box>

                    {/* Container avec la bubble classement et le nom */}
                    <Grid container width="60%" wrap="nowrap" direction={"row"} gap={1} alignItems={'center'} sx={{top:"0px" ,left:"0px" ,position:'relative' ,backgroundColor: isLosing ? "#BBBBBB":"white",borderRadius: "200px",border:"1px solid rgba(0,0,0,0.5)"}}>
                        {/* Bubble violette avec la photo et le classement uniquement */}
                        <Stack direction='row' spacing={2} alignItems={"center"}
                               sx={{
                                   border:"1px solid rgba(0,0,0,0.5)",
                                   borderRadius: "200px",
                                   backgroundColor : isLosing ? "#333333":"#B650FA",
                               }}
                        >
                            {/* Juste pour crée un spacing de 8 */}
                            <Typography> </Typography>
                            <Typography color={'primary'} sx={{fontWeight:"600"}}>
                                {classement}
                            </Typography>
                            <Avatar src={url}>
                            </Avatar>
                        </Stack>
                        {/* Nom du players */}
                        <Typography color={isLosing ? "white":"#B650FA"} sx={{fontWeight:"600"}}> {name} </Typography>
                    </Grid>
                </Box>
        // {/*</motion.Grid>*/}
        // {/* </motion.Box>*/}
    );
}

export default OnChainScore