import React, {useEffect, useState} from "react";
import StyledAvatar from "../customAvatar";
import {Grid, Typography} from "@mui/material";
import redCross from "../../assets/redcross.svg";
import GameHeader from "../ui/gameHeader";

const ShowElimination = ({data, players}) => {
    let angle = 360 - 90, dangle = 360 / (data.contenders.length);
    let distance = 350;
    const eliminatedContender = data.contenders.find(contender => contender.id === data.eliminated[data.eliminated.length - 1]);

    const [lastEliminated, setLastEliminated] = useState(null);

    useEffect(() => {
        // console.log("elimination ?")
        if (data.eliminated && data.eliminated.length > 0) {
            // console.log('el', data.eliminated[data.eliminated.length - 1])
            setLastEliminated(data.eliminated[data.eliminated.length - 1]);
            setTimeout(() => {
                setLastEliminated(null);
            }, 2000);
        }
    }, [data.eliminated]);

    return (
        <Grid container flexDirection="column" wrap="nowrap">
            <Grid item textAlign="center">
                <GameHeader description={`${eliminatedContender.name} à été éliminé !`} title="Élimination" players={players} />
            </Grid>

            <Grid item textAlign="center" marginTop="1em">
                <Typography variant="h5" color="secondary">{eliminatedContender.name} à été éliminé !</Typography>
            </Grid>

            <Grid item marginTop="300px">
                <div className="bigcircle">
                    <div className="bachelor">
                        <div>
                            <StyledAvatar
                                src={`${data.bachelor.url}`}
                                sx={{width: 150, height: 150}}/>
                            <Typography variant="body1" color="secondary" textAlign="center">{data.bachelor.name}</Typography>
                        </div>
                    </div>

                    {Object.values(data.contenders).map((user, i) => {
                        const isEliminated = data.eliminated && data.eliminated.includes(user.id);
                        const isLastEliminated = lastEliminated && lastEliminated === user.id;

                        return (
                            <div key={i}
                                 className={`contender avatar ${
                                     isEliminated ? 'eliminated' : ''}`}
                                 style={{
                                     transform: `rotate(${angle + (i * dangle)}deg) translate(${distance / 2}px) rotate(-${angle}deg)`,
                                     transition: 'transform .8s ease-in-out'
                                 }}>
                                <div className='avatarcontainer' style={{transform: `rotate(-${angle + (i * dangle)}deg) rotate(${angle}deg)`}}>
                                    <StyledAvatar src={`${user.url}`} sx={{width: 100, height: 100}}/>
                                    <Typography variant="body1" color="secondary" textAlign="center">{user.name}</Typography>
                                    {
                                        isEliminated ? <img src={redCross} className="eliminatedcross" alt="cross"
                                                            style={ isLastEliminated ? {animation: 'cross-appear 2s ease-in-out forwards'}: {}} /> : ''
                                    }

                                </div>
                            </div>
                        )
                    })}
                    <div className="sonar-wave"></div>
                </div>
            </Grid>
        </Grid>
    );
};

export default ShowElimination;