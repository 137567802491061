import React, {useEffect, useRef, useState} from "react";
import StyledAvatar from "../customAvatar";
import {Button, Grid, Typography} from "@mui/material";
import LinearDeterminate from "../linearDeterminate";
import {useSelector} from "react-redux";
import Box from "@mui/material/Box";
import AnswerInput from "../answerInput";

import GameExplanation from "../gameExplanation";
import GameExplanationImageBachelor from '../../assets/explanation_finale_bachelor.svg';
import {formatGame} from "../../services/util";
import GameHeader from "../ui/gameHeader";

const BachelorChat = ({socket, data: {contenders, historic}, duration}) => {
    const user = useSelector((state) => state.auth.user);

    const [chatMessages1, setChatMessages1] = useState([]);
    const [chatMessages2, setChatMessages2] = useState([]);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const [activeChat, setActiveChat] = useState(1);
    const [notification1, setNotification1] = useState(0);
    const [notification2, setNotification2] = useState(0);

    const [displayChoice, setDisplayChoice] = useState(false);
    const [displayChoiceWarning, setDisplayChoiceWarning] = useState(false);

    const messagesEnd1Ref = useRef(null);
    const messagesEnd2Ref = useRef(null);

    const scrollToBottom = (messagesEndRef) => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        const receiveMessageHandler = (data) => {

            // console.log('receive message', data)
            if(data.sender === contenders[0].id) {
                setChatMessages1((prevMessages) => [...prevMessages, data]);
                scrollToBottom(messagesEnd1Ref);
                if(activeChat !== 1)
                    setNotification1(notification1+1)
                else
                    setNotification1(0)
            } else if(data.sender === contenders[1].id) {
                setChatMessages2((prevMessages) => [...prevMessages, data]);
                scrollToBottom(messagesEnd2Ref);
                if(activeChat !== 2)
                    setNotification2(notification2+1)
                else
                    setNotification2(0)
            }
        };

        console.log("histoooric", historic)
        socket.on('receive_message', receiveMessageHandler);

        return () => {
            socket.off('receive_message', receiveMessageHandler);
        };
    }, [socket, activeChat, notification1, notification2]);

    useEffect(() => {
        socket.on('allow_choice', () => {
            setDisplayChoice(true);
        });
    }, [socket]);


    const sendMessage1 = (e) => {
        e.preventDefault();
        if (message1 !== '') {
            socket.emit('send_message', { message: message1, receiver: contenders[0].id});

            const sentMessage = { message: message1, sender: user._id };
            setChatMessages1((prevMessages) => [...prevMessages, sentMessage]);
            setMessage1('');
        }
    };

    const sendMessage2 = (e) => {
        e.preventDefault();
        if (message2 !== '') {
            socket.emit('send_message', { message: message2, receiver: contenders[1].id });

            const sentMessage = { message: message2, sender: user._id };
            setChatMessages2((prevMessages) => [...prevMessages, sentMessage]);
            setMessage2('');
        }
    };

    const changeActiveChat = (id) => {
        if(id === 1) {
            setActiveChat(1);
            setNotification1(0);
        } else {
            setActiveChat(2);
            setNotification2(0);
        }
    }

    const selectMatch = () => {
        socket.emit('select_match');
    }

    return (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
            <GameExplanation url={GameExplanationImageBachelor} />
            <div>
                <Grid container justifyContent="space-around" wrap="nowrap">
                    <Grid item onClick={() => changeActiveChat(1)} className={`chatHeaderBachelor ${activeChat === 1 ? "chatActiveUser" : ""}`}>
                        <div style={{position: 'relative'}}>
                            {notification1 > 0 ? <div className='chatNotification'>{notification1}</div> : ''}
                            <StyledAvatar src={`${contenders[0].url}`} sx={{ width: 50, height: 50}}/>
                        </div>
                        <div style={{margin: '10px'}}>
                            <Typography variant="h6" color={activeChat === 1 ? 'primary' : 'secondary'}>{contenders[0].name}</Typography>
                            <Typography variant="body2" color='#BBBBBB'></Typography>
                        </div>
                    </Grid>

                    <Grid item onClick={() => changeActiveChat(2)} className={`chatHeaderBachelor ${activeChat === 2 ? "chatActiveUser" : ""}`}>
                        <div style={{position: 'relative'}}>
                            {notification2 > 0 ? <div className='chatNotification'>{notification2}</div> : ''}
                            <StyledAvatar src={`${contenders[1].url}`} sx={{ width: 50, height: 50}}/>
                        </div>
                        <div style={{margin: '10px'}}>
                            <Typography variant="h6" color={activeChat === 2 ? 'primary' : 'secondary'}>{contenders[1].name}</Typography>
                            <Typography variant="body2" color='#BBBBBB'></Typography>
                        </div>
                    </Grid>
                </Grid>
                <LinearDeterminate duration={duration}/>
            </div>

            {displayChoice ?
                <div className="choosematch">
                    {displayChoiceWarning ?
                        <div className="warning">
                            <Typography variant="body2" color="secondary" textAlign="center">Sélectionner ton match terminera la discussion</Typography>
                            <div>
                                <Button onClick={selectMatch}><Typography variant="body2">j'ai fais mon choix</Typography></Button>
                                <Button onClick={() => setDisplayChoiceWarning(false)}><Typography variant="body2">continuer la discussion</Typography></Button>
                            </div>
                        </div>
                        :
                        <Button variant="activated" onClick={() => setDisplayChoiceWarning(true)}><Typography variant="body2" color="primary">choisir mon match</Typography></Button>
                    }
                </div>
                : ''}

            <form onSubmit={sendMessage1} className='chat' style={ activeChat === 1 ? {}: {display: 'none'}}>
                <div className="messagesColumn">
                    {historic && historic[contenders[0].id].map((game, i) => {
                        return formatGame(game, i, user, contenders[0].url);
                    })}

                    {chatMessages1.map((msg, i) => (
                        <div className={`message ${msg.sender === user._id ? "me" : "other"}`} key={i}>
                            <Typography variant="body2" className="msgText">{msg.message}</Typography>
                        </div>
                    ))}
                    <div ref={messagesEnd1Ref}>
                    </div>
                </div>

                <Box className="sendMessageContainer">
                    {/*<input*/}
                    {/*    type="text"*/}
                    {/*    placeholder='Message...'*/}
                    {/*    onChange={(e) => setMessage1(e.target.value)}*/}
                    {/*    value={message1}*/}
                    {/*/>*/}
                    {/*<button type="submit" disabled={!message1}></button>*/}
                    <Box margin={2}>
                        <AnswerInput answer={message1} handleSubmit={sendMessage1} handleChange={(e) => setMessage1(e.target.value)}/>
                    </Box>
                </Box>
            </form>

            <form onSubmit={sendMessage2} className='chat' style={ activeChat === 2 ? {}: {display: 'none'}} >
                <div className="messagesColumn">
                    {historic && historic[contenders[1].id].map((game, i) => {
                        return formatGame(game, i, user, contenders[1].url);
                    })}

                    {chatMessages2.map((msg, i) => (
                        <div className={`message ${msg.sender === user._id ? "me" : "other"}`} key={i}>
                            <Typography variant="body2"  className="msgText">{msg.message}</Typography>
                        </div>
                    ))}
                    <div ref={messagesEnd2Ref}>
                    </div>
                </div>

                <div className="sendMessageContainer">
                    {/*<input*/}
                    {/*    type="text"*/}
                    {/*    placeholder='Message...'*/}
                    {/*    onChange={(e) => setMessage2(e.target.value)}*/}
                    {/*    value={message2}*/}
                    {/*/>*/}
                    {/*<button type="submit" disabled={!message2}></button>*/}
                    <AnswerInput answer={message2} handleSubmit={sendMessage2} handleChange={(e) => setMessage2(e.target.value)}/>
                </div>
            </form>
        </Box>
    );
};

export default BachelorChat;