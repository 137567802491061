export const getGroupData = async (inviteId, userId, token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/session/group`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({inviteId, userId}),
        });

        if (!response.ok) {
            throw new Error("Erreur: Impossible de récupérer les informations du groupe");
        }

        return await response.json();
    } catch (error) {
        // throw new Error(error.message);
        return null;
    }
};