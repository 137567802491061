import {Grid, Typography} from "@mui/material";
import React from "react";
import IconBliss from "../../assets/blackicon_bliss.png";

const Header = () => {
    return (
        <Grid item display="flex" alignItems="center" gap={1} margin="1em auto 0.5em auto" width="100%" justifyContent="center">
            <img src={IconBliss} alt="icon bliss" height="auto" width="32px"/>
            <Typography color="secondary" variant="h4">bliss</Typography>
        </Grid>
        // <Typography
        //     variant="h4"
        //     alignItems="center"
        //     margin={"auto"}
        //     sx={{mt:'1em', mb: '0.5em'}}>
        //     bliss
        // </Typography>
    );
}

export default Header