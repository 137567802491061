import React, {useState} from "react";
import {Button, Grid, Typography} from "@mui/material";
import StyledAvatar from "../customAvatar";
import LinearDeterminate from "../linearDeterminate";
import Box from "@mui/material/Box";

// Composant
const SelectMatch = ({socket, data : {contenders, bachelor}, duration}) => {
    const [matchingUser, setMatchingUser] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        // with acknowledgement
        socket.emit("match_selection", { idUserToKeep : matchingUser.id });
    };

    return (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between" textAlign="center">
            <div className="chatHeader">
                <div className="chatHeaderInfo">
                    <StyledAvatar src={`${bachelor.url}`} sx={{ width: 50, height: 50}} />
                    <div style={{margin: '10px'}}>
                        <Typography variant="h5" color="secondary">{bachelor.name}</Typography>
                        <Typography variant="body2" color="#BBBBBB">Fais ton choix !</Typography>
                    </div>
                </div>
                <LinearDeterminate duration={20000}/>
            </div>

            {/*<div style={{textAlign: "center", padding: "5px 15px", margin: "10px"}}>*/}
            {/*    <StyledAvatar src={`${bachelor.url}`} sx={{ width: 60, height: 60}}/>*/}
            {/*    <h3>{bachelor.name}</h3>*/}
            {/*</div>*/}

            <Typography variant="h5" color="secondary" pt="2em">Choisis le profil avec qui tu veux continuer de discuter</Typography>

            <Grid container justifyContent="center" flexDirection="row" flex="0 1 50%" alignItems="center">
                <Grid item style={{cursor: "pointer", padding: "5px 15px", margin: "10px"}}
                      onClick={() => setMatchingUser(contenders[0])} className={`${matchingUser === contenders[0] ? "chatActiveUser" : ""}`}>
                    <StyledAvatar src={`${contenders[0].url}`} sx={{ width: 75, height: 75}}/>
                    <Typography variant="h6" color="secondary" pt="0.5">{contenders[0].name}</Typography>
                </Grid>

                <Grid item style={{textAlign: "center", cursor: "pointer", padding: "5px 15px", margin: "10px"}} onClick={() => setMatchingUser(contenders[1])} className={`${matchingUser === contenders[1] ? "chatActiveUser" : ""}`}>
                    <StyledAvatar src={`${contenders[1].url}`} sx={{ width: 75, height: 75}}/>
                    <Typography variant="h6" color="secondary" pt="0.5">{contenders[1].name}</Typography>
                </Grid>
            </Grid>

            <Typography variant="h5" color="secondary" pt="2em">Continuer l'aventure avec {matchingUser.name}</Typography>

            <div style={{marginTop: 'auto', marginBottom: "1em"}}>
                <Button variant="activated" type="submit" disabled={!matchingUser} onClick={handleSubmit}>Match</Button>
            </div>

        </Box>
    );
};

export default SelectMatch;