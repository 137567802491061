import React from "react";
import iconBlissBorders from "../assets/icon_bliss_borders.png";
import iconBliss from "../assets/icon_bliss_icon.png";
import {Backdrop} from "@mui/material";

const Loader = ({visible = false}) => {
    return (
        <Backdrop
            sx={{ bgcolor: visible ? 'rgba(0, 0, 0, 0.2)' : 'var(--primary)', zIndex: 2 }}
            open={true} >
            <div className="loaderContainer">
                <div className="iconLoaderBorder" style={{background: `url(${iconBlissBorders}) center no-repeat`, backgroundSize: 'contain' }}></div>
                <div className="iconLoader" style={{background: `url(${iconBliss}) center no-repeat`, backgroundSize: 'contain' }}></div>
            </div>
        </Backdrop>
    );
}

export default Loader