import React, {useEffect, useState} from "react";
import Answer, {TYPES} from "../answer";
import GameHeader from "../ui/gameHeader";
import Box from "@mui/material/Box";
import BuildQuestionBox from "../buildQuestionBox";
import BuildAnswersBox from "../buildAnswersBox";
import {Divider, Grid, Typography} from "@mui/material";

import GameExplanation from "../gameExplanation";
import GameExplanationImageBachelor from '../../assets/explanation_build_bachelor.svg';
import GameExplanationImageContender from '../../assets/explanation_build_contender.svg';


const Build = ({socket, data: {question, themes, isBachelor}, initialDuration, players}) => {
    const [answer, setAnswer] = useState("");
    const [currentTheme, setCurrentTheme] = useState(0);
    const [responses, setResponses] = useState([{}, {}, {}]);
    const [bachelorResponses, setBachelorResponses] = useState([{}, {}, {}]);
    const [duration, setDuration] = useState(initialDuration);

    const contenders = players.contenders.filter((contender) => !contender.isEliminated);

    useEffect(() => {
        socket.on('game_results', handleGameResults);
        socket.on('update_duration', handleUpdateDuration);

        return () => {
            socket.off('game_results', handleGameResults);
            socket.off('skip_answer', handleSkipAnswer);
            socket.off('update_duration', handleUpdateDuration);
        };
    }, []);

    useEffect(() => {
        socket.on('skip_answer', handleSkipAnswer);

        return () => {
            socket.off('skip_answer', handleSkipAnswer);
        };
    }, [currentTheme]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if(answer) {
            // console.log("submit to server", answer);

            socket.emit("game_response", {answer}, (data) => {
                setCurrentTheme(currentTheme + 1);
                setAnswer("");
                setDuration(data.duration);
                // console.log("duration contender game_response callback", data.duration);
            });
        }
    }

    const handleClick = (userId) => {
        // console.log("submit to server", userId);

        socket.emit("game_response", {answer: userId}, (data) => {
            setCurrentTheme(currentTheme + 1);
            setDuration(data.duration);
            // console.log("duration bachelor game_response callback", data.duration);

        });
    }

    const handleChange = (e) => {
        setAnswer(e.target.value);
    }

    const handleGameResults = ({contenders, bachelor}) => {
        if(contenders) {
            const {userId, themeNumber, answer} = contenders;

            setResponses(prevResponse => {
                const updatedResponse = [...prevResponse]; // Create a copy of the response array

                // Update the response object at the specified theme number
                updatedResponse[themeNumber] = { ...updatedResponse[themeNumber], [userId]: answer };

                return updatedResponse;
            });
        }

        if(bachelor) {
            const {userId, themeNumber, previouslySelectedUserIds} = bachelor;
            setBachelorResponses(prevResponse => {
                const updatedResponse = [...prevResponse];

                // Update the response object at the specified theme number
                updatedResponse[themeNumber] = {
                    ...updatedResponse[themeNumber],
                    selectedUserId: userId,
                    previouslySelectedUserIds
                };

                // Update the subsequent objects
                for (let i = themeNumber + 1; i < updatedResponse.length; i++) {
                    updatedResponse[i].previouslySelectedUserIds = previouslySelectedUserIds;
                }

                return updatedResponse;
            });
        }
    }

    const handleSkipAnswer = (data) => {
        setCurrentTheme(currentTheme + 1);
        setAnswer("");
        setDuration(data.duration);
        // console.log("duration contender handleSkipAnswer callback", data.duration);
    }

    const handleUpdateDuration = ({duration}) => {
        setDuration(duration);
        // console.log("duration bachelor handleUpdateDuration callback", duration);
    }

    return (
        <Box height="100%">
            <GameExplanation url={isBachelor ? GameExplanationImageBachelor : GameExplanationImageContender} />
            <GameHeader duration={duration}
                        description={isBachelor ? "Les prétendants vont te proposer un scénario fictif \n Sélectionne ta réponse préférée sur chaque thème" : "Imagine un scénario fictif d’une rencontre avec l’élu en fonction des thèmes qui te sont proposés"}
                        title="Notre parfait premier" players={players}/>

            <Grid container width="90%" display="flex" flexDirection="column" justifyContent="flex-start" margin="auto" gap={1}>
                <Grid item>
                    <Answer url={players.bachelor.url} sentence={question.question} name={players.bachelor.name} type={TYPES.Color}/>
                </Grid>

                {isBachelor && currentTheme < 3 && (
                    <Grid item flexDirection={'column'} alignItems={"center"}>
                        <Divider>
                            <Typography textAlign="center">{contenders.length === Object.keys(responses[currentTheme]).length ? "Sélectionne ta réponse préférée !" : "Attends que les prétendants répondent"}</Typography>
                        </Divider>
                    </Grid>
                )}



                {themes.slice(0, currentTheme + 1).map((theme, index) => (
                    isBachelor
                        ?
                        <Grid item>
                            <BuildAnswersBox key={index} suffix={index > 0 ? themes[index - 1].suffix : ''} theme={theme}
                                             responses={responses[index]} contenders={contenders} handleClick={handleClick}
                                             isBachelor={isBachelor} bachelorResponses={bachelorResponses[index]}/>
                        </Grid>
                        :
                        currentTheme === index
                            ?
                            <Grid item>
                                <BuildQuestionBox key={index} suffix={index > 0 ? themes[index - 1].suffix : ''} theme={theme}
                                              handleSubmit={handleSubmit} answer={answer} handleChange={handleChange}/>
                            </Grid>
                            :
                            <Grid item>
                                <BuildAnswersBox key={index} suffix={index > 0 ? themes[index - 1].suffix : ''} theme={theme}
                                             responses={responses[index]} contenders={contenders} bachelorResponses={bachelorResponses[index]}/>
                            </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Build;