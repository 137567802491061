import Box from "@mui/material/Box";
import {Chip, Grid, Typography} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import DotsAnimation from "./dotsAnimation";
import React, {useEffect, useState} from "react";
import StyledAvatar from "./customAvatar";

const BuildAnswersBox = ({suffix, theme, responses, contenders, bachelorResponses, isBachelor = false, handleClick = null}) => {
    const [answer, setAnswer] = useState(null);

    const handleBachelorClick = (userId) => {
        // console.log(userId);
        setAnswer(userId);
        handleClick(userId);
    }

    useEffect(() => {
        // console.log(bachelorResponses)
        // console.log(contenders)
    }, [bachelorResponses]);

    const allUserAnswered = contenders.length === Object.keys(responses).length;

    return (
        <Grid className="buildGrid" container height="100%" display="flex" flexDirection="column" padding={1} position='relative'>
            <Grid item>
                <Typography variant="body1">{suffix} {theme.prefix}</Typography>
            </Grid>
            <Grid item marginBottom={0.5}>
               <Chip label={<Typography>{theme.logo} {theme.name}</Typography>} color="tertiary" size="small" />
            </Grid>
            <Grid item container spacing={1} sx={{ gridTemplate:'repeat(2,1fr)' }}>
                {contenders.map((contender, i) => {
                    const canClick = isBachelor && allUserAnswered && (!answer && !bachelorResponses.selectedUserId) && !bachelorResponses.previouslySelectedUserIds?.includes(contender.id);
                    const className = contender.id === answer || contender.id === bachelorResponses.selectedUserId ? 'activeChip' :
                        bachelorResponses.previouslySelectedUserIds?.includes(contender.id) ? 'previouslySelectedChip' : 'basicChip'

                    return (<Grid key={i} item xs={6} >
                        <Chip onClick={() => canClick ? handleBachelorClick(contender.id) : ''}
                              sx={{display: 'flex', justifyContent: 'flex-start'}}
                              avatar={<Avatar alt="avatar" src={bachelorResponses.selectedUserId ? contender.url : ''}/>}
                              label={<Typography variant="body2" color='primary' component="div">{responses[contender.id] === '' ? 'Pas de réponse' : responses[contender.id] ? responses[contender.id] : <DotsAnimation/>}</Typography>}
                              className={className}
                              // color="lightTertiary" Utiliser le conditionnel sur l'attribut color/sx au lieu des className
                        />
                    </Grid>)
                })}
            </Grid>

            {bachelorResponses.selectedUserId ?
                <Box sx={{position: "absolute", top: "5px", right: "5px"}}>
                    <StyledAvatar src={contenders.find(user => user.id === bachelorResponses.selectedUserId)?.url} sx={{width: 30, height: 30}}/>
                </Box>
                : ''}
        </Grid>
    );
};

export default BuildAnswersBox;