// V1
// export const createGuest = async (userData) => {
//     try {
//         const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/guest`, {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(userData),
//         });
//
//         if (!response.ok) {
//             throw new Error("Erreur: Impossible de se connecter en tant qu'invité");
//         }
//
//         return await response.json();
//     } catch (error) {
//         throw new Error(error.message);
//     }
// };
//
// // V1
// export const createAccount = async (userData, token) => {
//     try {
//         const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/`, {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`,
//             },
//             body: JSON.stringify(userData),
//         });
//
//         if (!response.ok) {
//             throw new Error("Erreur: Impossible de créer un compte");
//         }
//
//         return await response.json();
//     } catch (error) {
//         // throw new Error(error.message);
//         return null;
//     }
// };

// V2
export const insertNewUser = async (token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error("Erreur: Impossible de créer un compte");
        }

        return await response.json();
    } catch (error) {
        // throw new Error(error.message);
        return null;
    }
};

// V2
export const createNewGuestUser = async (userData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/guest`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userData)
        });

        if (!response.ok) {
            throw new Error("Erreur: Impossible de créer un compte en tant qu'invité");
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export const finishRegistration = async (userData, token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/finishRegistration`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(userData)
        });

        if (!response.ok) {
            throw new Error("Erreur: Impossible de créer un compte");
        }

        return await response.json();
    } catch (error) {
        throw new Error(error.message);
    }
};


// V1
export const getLoginUserAccount = async (token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
        });

        if (!response.ok) {
            throw new Error("Erreur: Impossible de récupérer les informations du compte");
        }

        return await response.json();
    } catch (error) {
        // throw new Error(error.message);
        return null;
    }
}

// V1
// export const updateGuest = async (id, userData, token) => {
//     try {
//         const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/guest/${id}`, {
//             method: "PATCH",
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`,
//             },
//             body: JSON.stringify(userData),
//         });
//
//         if (!response.ok) {
//             throw new Error("Erreur: Impossible de se créer un compte");
//         }
//
//         return await response.json();
//     } catch (error) {
//         // throw new Error(error.message);
//         return null;
//     }
// };