import React from "react";

const DotsAnimation = () => {
    return (
        <p className="typing">
            <span></span>
            <span></span>
            <span></span>
        </p>
    );
}

export default DotsAnimation