import {useDispatch, useSelector } from 'react-redux';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {useEffect} from "react";
import {setInviteId} from "../redux/invite.slice";
import {ampli} from "../ampli";

const JoinWrapperRoute = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const inviteParam = searchParams.get('invite');

        console.log(inviteParam);

        if (inviteParam) {
            // console.log('Invite parameter:', inviteParam);
            dispatch(setInviteId(inviteParam));

            ampli.joinFriend({groupId: inviteParam});

            if (user) {
                // Navigate to /app/dashboard
                navigate("/app/dashboard", { replace: true });
            }
        }
    }, [location.search]);

    return (
        <Outlet />
    );
}

export default JoinWrapperRoute;