import React, {useEffect, useRef, useState} from "react";
import StyledAvatar from "../customAvatar";
import LinearDeterminate from "../linearDeterminate";
import {useSelector} from "react-redux";
import AnswerInput from "../answerInput";

import GameExplanation from "../gameExplanation";
import GameExplanationImageContender from '../../assets/explanation_finale_contender.svg';
import {formatGame} from "../../services/util";
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";

const ContenderChat = ({socket, data, duration}) => {
    const user = useSelector((state) => state.auth.user);

    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);

    const messagesEndRef = useRef(null)

    useEffect(() => {
        const receiveMessageHandler = (data) => {
            // console.log("message received", data)
            setMessages((prevMessages) => [...prevMessages, data]);
            scrollToBottom();
        };

        socket.on('receive_message', receiveMessageHandler);

        console.log("hostiric ?", data);

        return () => {
            socket.off('receive_message', receiveMessageHandler);
        };
    }, [socket]);

    const sendMessage = (e) => {
        e.preventDefault();
        if (message !== '') {
            socket.emit('send_message', { message });

            const sentMessage = { message, sender: user._id };
            setMessages((prevMessages) => [...prevMessages, sentMessage]);
            setMessage('');
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    return (
        <form onSubmit={sendMessage} className="chat">
            <GameExplanation url={GameExplanationImageContender} />

            <div className="chatHeader">
                <div className="chatHeaderInfo">
                    <StyledAvatar src={`${data.bachelor.url}`} sx={{ width: 50, height: 50}} />
                    <div style={{margin: '10px'}}>
                        <Typography variant="h6">{data.bachelor.name}</Typography>
                        <Typography variant="body2" color='#BBBBBB'>Chat pendant {Math.round(duration/1000/60)} min</Typography>
                    </div>
                </div>
                <LinearDeterminate duration={duration}/>
            </div>

            <div className="messagesColumn" >
                {data.historic && data.historic.map((game, i) => {
                    return formatGame(game, i, user, data.bachelor.url);
                })}

                {data.responses?.map((response, i) => (
                    <div className="game" key={i}>
                        <p>{response.question}</p>
                        <p className="gameAnswer">{response.answer ? response.answer : "Pas de réponse"}</p>
                    </div>
                ))}

                {messages.map((msg, i) => (
                    <div className={`message ${msg.sender === user._id ? "me" : "other"}`} key={i}>
                        <Typography variant="body2" className="msgText">{msg.message}</Typography>
                    </div>
                ))}
                <div style={{minHeight: '40px', width:'10px'}}>
                </div>
                <div ref={messagesEndRef}>
                </div>
            </div>

            <div className="sendMessageContainer">
                {/*<input*/}
                {/*    type="text"*/}
                {/*    placeholder='Message...'*/}
                {/*    onChange={(e) => setMessage(e.target.value)}*/}
                {/*    value={message}*/}
                {/*/>*/}
                {/*<button type="submit" disabled={!message}>*/}
                {/*    /!*<img className="btnlogo" src={IconSendMessage} alt="send"/>*!/*/}
                {/*</button>*/}
                <Box margin={2}>
                    <AnswerInput answer={message} handleSubmit={sendMessage} handleChange={(e) => setMessage(e.target.value)}/>
                </Box>
            </div>
        </form>
    );
};

export default ContenderChat;