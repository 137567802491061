import React, {forwardRef} from "react";
import {Alert, CircularProgress, Grid, TextField, Typography} from "@mui/material";

import Box from "@mui/material/Box";
import {FaInfoCircle} from 'react-icons/fa';

import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import SubmitButton from "../mui/submitButton";

const CustomPhoneInput = forwardRef((props, ref) => {
    const { value, onChange, ...rest } = props;

    return (
        <TextField
            {...rest}
            variant="outlined"
            color="secondary"
            size="small"
            fullWidth={true}
            inputRef={ref}
            value={value}
            onChange={onChange}
        />
    );
});

const MobileNumber = ({phoneNumber, onPhoneNumberChange, onButtonClick, loading, messageStatus}) => {
    return (
        <Box display="flex" flex="1 1 auto" flexDirection="column" justifyContent="space-between">
            <Grid item container flex="1 1 auto" sx={{ width: '100%' }}  wrap='nowrap' direction="column" justifyContent="space-between">
                <Grid item container wrap='nowrap' direction="column" rowSpacing={2}>
                    <Grid item>
                        <Typography variant="h4">T'as un numéro ?</Typography>
                    </Grid>
                    <Grid item width="100%" textAlign="center">

                        <PhoneInput
                            className="phoneinput"
                            style={{border: 'none'}}
                            value={phoneNumber}
                            defaultCountry="FR"
                            onChange={onPhoneNumberChange}
                            countryCallingCodeEditable={false}
                            international
                            inputComponent={CustomPhoneInput}/>
                    </Grid>

                    <Grid item id="recaptcha-container"></Grid>

                    {messageStatus ?
                        <Grid item sx={{ width: '100%'}}>
                            <Alert severity="error"><Typography variant="caption">{messageStatus}</Typography></Alert>
                        </Grid> : ''
                    }
                </Grid>

                <Grid container wrap='nowrap' direction="column" rowSpacing={2}>
                    <Grid item>
                        <Box className="registerinfo">
                            <FaInfoCircle size={25} />
                            <Typography color="secondary" variant="caption">On va t'envoyer un code pour vérifier ton identité. Si tu as déjà un compte cela te connectera</Typography>
                        </Box>
                    </Grid>
                    <Grid item sx={{ width: '100%'}} mb={4}>
                        <SubmitButton onButtonClick={onButtonClick}
                                      disabled={!phoneNumber || !isValidPhoneNumber(phoneNumber)}
                                      color="secondary"
                                      variant="contained"
                                      text="Continuer"
                                      iconAfter={loading ? <CircularProgress size="20px" /> : ''}/>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default MobileNumber