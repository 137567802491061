import AWS from "aws-sdk";

export const uploadFile = (file) => {
    const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION
    });

    // Generate a random file name with timestamp
    const fileName = `users/image_${Date.now()}_${Math.random().toString(36).slice(2, 7)}_${file.name}`;

    const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: fileName,
        Body: file,
        ContentType: file.type,
        ACL: 'public-read'
    };

    return new Promise((resolve, reject) => {
        s3.upload(params, (err, data) => {
            if (err) {
                // console.log(err);
                reject(err);
            } else {
                // console.log(`File uploaded successfully. ${data.Location}`);
                resolve(data.Location);
            }
        });
    });
}