import React, {useEffect, useState} from "react";
import StyledAvatar from "../customAvatar";
import {Grid, Modal, Typography} from "@mui/material";
import LinearDeterminate from "../linearDeterminate";
import redCross from "../../assets/redcross.svg";
import Box from "@mui/material/Box";
import UserCard from "../userCard";
import {GrFormClose} from "react-icons/gr";
import ProfilCard from "../profilCard";

const GameHeader = ({title, description, duration, players: {bachelor, contenders}}) => {
    const [selectedPlayer, setSelectedPlayer] = useState(null);

    useEffect(() => {
        console.log("duration change", duration)
    }, [duration])

    const showPlayerProfil = (userId) => {
        const players = [...contenders, bachelor];

        if(selectedPlayer && userId === selectedPlayer.id) {
            setSelectedPlayer(null);
        } else {
            setSelectedPlayer(players.find(user => user.id === userId));
        }
    }

    return (
       <Grid container className='gameHeader' flexDirection="column" width="100%" mb="1rem">
           <Typography variant="h6">{title}</Typography>
           <Typography variant="body1" pl={1} pr={1}>{description}</Typography>
           <Box className="avatarHeader">
               <Box sx={{padding: '0 3px'}}>
                   <StyledAvatar src={`${bachelor.url}`} sx={{ width: 35, height: 35 }} onClick={() => showPlayerProfil(bachelor.id)}/>
               </Box>
               <Box sx={{display: 'flex'}}>
                   {contenders.map((player, i) => (
                       <Box key={i} sx={{padding: '0 3px', position:'relative'}}>
                           {
                               player.isEliminated ? <img src={redCross} className="eliminatedcrossHeader" alt="cross"/> : ''
                           }
                           <StyledAvatar src={`${player.url}`} sx={{ width: 35, height: 35 }} onClick={() => showPlayerProfil(player.id)}/>
                       </Box>
                   ))}
               </Box>
           </Box>
           {selectedPlayer &&

               <ProfilCard handleClose={() => setSelectedPlayer(null)}
                    url={selectedPlayer.url} name={selectedPlayer.name} bio={selectedPlayer.bio} age={selectedPlayer.age}/>
               // <Modal
               //     open={selectedPlayer}
               //     onClose={() => setSelectedPlayer(null)}
               //     sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               //     <Box className='headerProfilModal'>
               //         <Box className='headerusercard'>
               //              <GrFormClose size={30} style={{position: 'absolute', top: '5px', right: '5px', zIndex: '10'}} onClick={() => setSelectedPlayer(null)}/>
               //              <UserCard src={selectedPlayer.url} name={selectedPlayer.name} bio={selectedPlayer.bio} age={selectedPlayer.age} />
               //          </Box>
               //     </Box>
               // </Modal>
           }

           {duration ? <LinearDeterminate duration={duration}/> :  <Box sx={{ width: '100%', height: '4px', bgcolor:'#B650FA' }}></Box>}
       </Grid>
    );
}

export default GameHeader