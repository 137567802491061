import React, {useEffect, useState} from "react";
import PreviewRoom from "../components/games/previewRoom";
import ChainWouldYouRather from "../components/games/chainWouldYouRather";
import ShowElimination from "../components/games/showElimination";
import BachelorChat from "../components/games/bachelorChat";
import ContenderChat from "../components/games/contenderChat";
import Sentence from "../components/games/sentence";
import Loser from "../components/games/loser";
import SentenceEliminate from "../components/games/sentenceEliminate";
import SelectMatch from "../components/games/selectMatch";
import Match from "../components/games/match";
import Build from "../components/games/build";
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import SentenceWithChoice from "../components/games/sentenceWithChoice";
import SentenceEliminateWithChoice from "../components/games/sentenceEliminateWithChoice";

function Game({socket, handleStop, game, setGame}) {
    const user = useSelector((state) => state.auth.user);

    const [winOrLose, setWinOrLose] = useState(null);
    const [players, setPlayers] = useState([]);

    const handleRedirect = () => {
        handleStop();
    }

    useEffect(() => {
        socket.on('game', data => {
            console.log('game', data);
            setGame(data);
        });

        socket.on('update_header', data => {
            // console.log('update_header', data);
            setPlayers(data);
        });

        socket.on('win_or_lose', data => {
            // console.log('win_or_lose', data);
            setWinOrLose(data);
        });

        // Return a cleanup function to inform the server that the user has left the game when the component is unmounted
        return () => {
            socket.emit('leave_game');
            socket.removeAllListeners('game');
            socket.removeAllListeners('win_or_lose');
            socket.removeAllListeners('on_update_header');
        }
    }, []);

    if(!game) {
        return (
            <div>
                <p>Searching for a game ...</p>
                <button onClick={handleStop}>Stop</button>
            </div>
        );
    }

    // Render the appropriate game component based on the game type
    let gameComponent;
    switch (game.type) {
        case "on_room_join":
            gameComponent = <PreviewRoom data={game.data} timeout={game.timeout} />;
            break;
        case "on_chain_would_you_rather":
            gameComponent = <ChainWouldYouRather socket={socket} data={game.data} initialDuration={game.duration} players={players} isBachelor={user._id === players?.bachelor?.id}/>;
            break;
        case "on_build":
            gameComponent = <Build socket={socket} data={game.data} initialDuration={game.duration} players={players} />;
            break;
        case "on_sentence":
            gameComponent = <Sentence socket={socket} data={game.data} duration={game.duration} players={players} />;
            break;
        case "on_sentence_eliminate":
            gameComponent = <SentenceEliminate socket={socket} data={game.data} initialDuration={game.duration} players={players} />;
            break;
        case "on_sentence_choice":
            gameComponent = <SentenceWithChoice socket={socket} data={game.data} initialDuration={game.duration} players={players} />;
            break;
        case "on_sentence_eliminate_choice":
            gameComponent = <SentenceEliminateWithChoice socket={socket} data={game.data} initialDuration={game.duration} players={players} />;
            break;
        case "show-elimination":
            gameComponent = <ShowElimination data={game.data} players={players} />;
            break;
        case "bachelor_chat":
            gameComponent = <BachelorChat socket={socket} data={game.data} duration={game.duration} />;
            break;
        case "contender_chat":
            gameComponent = <ContenderChat socket={socket} data={game.data} duration={game.duration} />;
            break;
        case "select_match":
            gameComponent = <SelectMatch socket={socket} data={game.data} duration={game.duration} />;
            break;
        default:
            gameComponent = <div>null</div>;
    }

    return (
        <Box height="100%">
            {gameComponent}
            {winOrLose && winOrLose.type === 'lose' && <Loser socket={socket} handleRedirect={handleRedirect} />}
            {winOrLose && winOrLose.type === 'win' && <Match socket={socket} data={winOrLose.data} players={players} handleRedirect={handleRedirect}/>}
        </Box>
    );

    // if(winOrLose && winOrLose.type === 'lose') {
    //     return <Loser socket={socket} /*replay={handleReplay}*/ />
    // } else if(winOrLose && winOrLose.type === 'win') {
    //     return <Match socket={socket} data={game.data} duration={game.duration}/>
    // }
}

export default Game;