import {Alert, Grid, ThemeProvider, Typography} from "@mui/material";
import {theme} from "../theme";
import React, {useEffect, useState} from "react";

import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import {IoIosArrowBack} from "react-icons/io";
import RegisterName from "../components/register/registerName";
import RegisterBirth from "../components/register/registerBirth";
import RegisterPictures from "../components/register/registerPictures";
import RegisterMore from "../components/register/registerMore";
import RegisterGender from "../components/register/registerGender";
import {ROLES} from "../App";
import {useDispatch, useSelector} from "react-redux";
import {createNewGuestUser, finishRegistration} from "../services/auth.service";
import {loginUser} from "../redux/auth.slice";
import Loader from "../components/loader";
import {ampli} from "../ampli";
import {compressAndUpload} from "../services/util";

const NB_STEPS = 5;
const IMAGE_STEP = 4;

const Register = ({socket}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {user, token, role} = useSelector((state) => state.auth);

    const [activeStep, setActiveStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [messageStatus, setMessageStatus] = useState('');

    const [imageObject, setImageObject] = useState('');
    const [image, setImage] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [needUpload, setNeedUpload] = useState(true);

    const [name, setName] = useState("");
    const [birth, setBirth] = useState(null);
    const [gender, setGender] = useState("");
    const [pictures, setPictures] = useState("");
    const [bio, setBio] = useState("");

    useEffect(() => {
        ampli.startSignUpProcess();
    }, []);

    useEffect(() => {
        const handlePopState = () => {
            if (activeStep > 1) {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
            }
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, [activeStep]);

    useEffect(() => {
       if(!needUpload && loading) {
           console.log(user.role)
           if(role === ROLES.NewGuestUser) {
               createGuestUser();
           } else {
               updateUserAfterRegistration();
           }
       }
    }, [needUpload, loading]);

    const updateUserAfterRegistration = async () => {
        const userData = {_id: user._id, name, gender, bio, birth, pictures: [imageUrl]};
        try {
            const dbuser = await finishRegistration(userData, token);

            socket.io.opts.query = {
                id: dbuser._id,
                token: token,
            };

            socket.connect();

            dispatch(loginUser({user: dbuser, token, role: ROLES.User}));

            setLoading(false);
            ampli.finishSignUpProcess();

            navigate("/app/dashboard", {replace: true});
        } catch(err) {
            setMessageStatus(err.toString());
            setLoading(false);
        }
    }

    const createGuestUser = async () => {
        console.log("new guest lol")
        const userData = {name, gender, bio, birth, pictures: [imageUrl]};
        try {
            createNewGuestUser(userData).then((dbResponse) => {
                console.log('create new user good', dbResponse);

                socket.io.opts.query = {
                    id: dbResponse.user._id,
                    token: token,
                };

                socket.connect();

                dispatch(loginUser({user: dbResponse.user, token: dbResponse.token, role: ROLES.Guest}));
                setLoading(false);
                ampli.signGuest();

                navigate("/app/dashboard", {replace: true});

                // auth.setPersistence(firebase.auth.Auth.Persistence.NONE).then(function() {
                //     return auth.signInWithCustomToken(dbResponse.token).then((response) => {
                //         console.log("signinwithtoken good ", response);
                //
                //         dispatch(loginUser({user: dbResponse.user, token: dbResponse.token, role: ROLES.Guest}));
                //         navigate("/app/dashboard", {replace: true});
                //     }).catch((error) => {
                //         // Handle Errors here.
                //         const errorCode = error.code;
                //         const errorMessage = error.message;
                //         if (errorCode === 'auth/invalid-custom-token') {
                //             alert('The token you provided is not valid.');
                //         } else {
                //             console.error(error);
                //         }
                //     });
                });
            // }).catch((err) => {
            //     console.log("err", err);
            // });

        } catch(err) {
            setMessageStatus(err.toString());
        }
    }

    const handleNext = async () => {
        // Upload img
        if(activeStep === IMAGE_STEP && needUpload) {
            compressAndUpload(image).then((url) => {
                console.log(url);
                setImageUrl(url);
                setNeedUpload(false);
            }).catch((err) => {
                console.log("err while uploading image", err);
            })
        }

        if(activeStep < NB_STEPS) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            window.history.pushState(null, "", "/register");
        } else {
            setLoading(true);
        }
    };

    const handleBack = () => {
        setMessageStatus('');
        window.history.back();
    };

    const handleNameChange = (e) => {
        if(e.target.value.length > 12) {
            return;
        } else {
            setName(e.target.value);
        }
    };

    const handleBirthChange = (newValue) => {
        setBirth(newValue);
    };

    const handleGenderChange = (newValue) => {
        setGender(newValue);
    };

    const handlePicturesChange = (e) => {
        setImage(e.target.files[0]);
        if (e.target.files[0]) {
            setImageObject(URL.createObjectURL(e.target.files[0]));
            setNeedUpload(true);
        }
    };

    const handleBioChange = (e) => {
        setBio(e.target.value);
    };

    const stepComponents = [
        <RegisterName name={name} onNameChange={handleNameChange} onButtonClick={handleNext}/>,
        <RegisterBirth birth={birth} onBirthChange={handleBirthChange} onButtonClick={handleNext}/>,
        <RegisterGender gender={gender} onGenderChange={handleGenderChange} onButtonClick={handleNext}/>,
        <RegisterPictures pictures={pictures} imageObject={imageObject} onPictureChange={handlePicturesChange} onButtonClick={handleNext} />,
        <RegisterMore bio={bio} onBioChange={handleBioChange} onButtonClick={handleNext}/>,
    ];

    return (
        <ThemeProvider theme={theme}>
            <Box className="load contentWrapper" sx={{height: '100%'}}>
                {loading ? <Loader visible={true} /> : ""}

                <Grid container wrap='nowrap' direction='column' height='100%' width="100%" alignItems="center" justifyContent="space-between" rowSpacing={2}>
                    <Grid item sx={{ width: '100%'}}>
                        <LinearProgress color='tertiary' variant="determinate" value={activeStep/NB_STEPS * 100} />
                    </Grid>

                    <Grid item width="80%">
                        {activeStep === 1 ?
                            <Box height="30px"></Box>
                            :
                            <IoIosArrowBack className="buttonGoBackward" size="35" color="#B650FA" onClick={handleBack}/>
                        }
                    </Grid>

                    <Grid item container flex='1 1 auto' sx={{ width: '80%' }}>
                        <Grid item width="100%">{stepComponents[activeStep - 1]}</Grid>
                    </Grid>

                    { messageStatus &&  <Grid item width="80%"><Alert severity="error"><Typography variant="body2">{messageStatus}</Typography></Alert></Grid> }

                </Grid>
            </Box>
        </ThemeProvider>
    );
};

export default Register;