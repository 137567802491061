import React, {useEffect, useState} from "react";
import StyledAvatar from "../customAvatar";
import {Grid, Typography} from "@mui/material";
import UserCard from "../userCard";
import Box from "@mui/material/Box";
import Header from "../ui/header";

const PreviewRoom = ({data: {contenders, bachelor}, timeout}) => {
    const [counter, setCounter] = useState(0);
    const [selectedContender, setSelectedContender] = useState(null);

    useEffect(() => {
        setCounter(timeout);
    }, []);

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    const showContenderProfil = (userId) => {
        if(selectedContender && userId === selectedContender.id) {
            setSelectedContender(null);
        } else {
            setSelectedContender(contenders.find(user => user.id === userId));
        }
    }

    return (
        // Box pour prendre toute la page
        <Box wrap='nowrap' height="100%" width="100%">
            {/* Grid avec les 2 composents "header" "body" */}
            <Grid container flexDirection='column' justifyContent="space-between" alignItems="center"
                  height='100%' wrap='nowrap'>
                <Grid item>
                    <Header/>
                </Grid>
                {/* Contenue de la page */}
                <Grid container item rowGap={0.5} flexDirection='column' justifyContent="flex-start" alignItems="center"
                      height='100%' wrap='nowrap' width={'95%'} margin={'auto'}>
                    {/* barre de recherche avec chips */}
                    <Grid item justifyContent={"flex-start"} alignItems={"center"}>
                        {/*<Typography>*/}
                        {/*    /!* todo : mettre conditionnelement prétendant ou bachelor *!/*/}
                        {/*    Séssion trouvé : tu sera 'inséré pretendant'*/}
                        {/*</Typography>*/}
                    </Grid>

                    {/* Début du composant carte */}
                    <Grid container id='ici' item sx={{width: '100%', aspectRatio: '3/4'}}>
                        <Grid item style={{position: "relative",width:'100%', height: '100%'}}>
                            <UserCard
                                bio={selectedContender ? selectedContender.bio : bachelor.bio}
                                name={selectedContender ? selectedContender.name : bachelor.name}
                                src={selectedContender ? selectedContender.url : bachelor.url}
                                age={selectedContender ? selectedContender.age : bachelor.age}>
                                {/* <button>Relancer</button> */}
                                <Typography color="white">
                                    Lancement dans : {counter}
                                </Typography>
                            </UserCard>
                            <Grid item container
                                  sx={{position: "absolute", top: '10px', left: "0", justifyContent: 'space-around'}}>
                                {contenders.map((contender) => (
                                    <Grid item key={contender.id}>
                                        <StyledAvatar src={contender.url} sx={{width: 50, height: 50}} onClick={() => showContenderProfil(contender.id)}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
};

export default PreviewRoom;