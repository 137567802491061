import React from "react";
import {Alert, CircularProgress, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";

import SubmitButton from "../mui/submitButton";
import VerificationCodeInputs from "./verificationCodeInputs";

const MobileVerificationCode = ({phoneNumber, verificationCode, onVerificationCodeChange, onButtonClick, loading, messageStatus}) => {
    return (
        <Box display="flex" flex="1 1 auto" flexDirection="column" justifyContent="space-between">
            <Grid item container flex="1 1 auto" sx={{ width: '100%' }}  wrap='nowrap' direction="column" justifyContent="space-between">
                <Grid item container wrap='nowrap' direction="column" rowSpacing={2}>
                    <Grid item>
                        <Typography variant="h4" mb={1}>Saisis le code de vérification</Typography>
                        <Typography variant="body1">{phoneNumber}</Typography>
                    </Grid>
                    <Grid item>
                    </Grid>
                    <Grid item width="100%" textAlign="center">
                        {/*<TextField variant="outlined" size="small" fullWidth={true}*/}
                        {/*           value={verificationCode}*/}
                        {/*           type="number"*/}
                        {/*           onChange={onVerificationCodeChange}*/}
                        {/*           InputProps={{*/}
                        {/*               inputProps: {*/}
                        {/*                   style: { textAlign: "center" },*/}
                        {/*               }*/}
                        {/*           }}/>*/}
                        <VerificationCodeInputs codeLength={6} onVerificationCodeComplete={onVerificationCodeChange} />
                    </Grid>

                    {messageStatus ?
                        <Grid item sx={{ width: '100%'}}>
                            <Alert severity="error"><Typography variant="caption">{messageStatus}</Typography></Alert>
                        </Grid> : ''
                    }
                </Grid>

                <Grid container wrap='nowrap' direction="column" rowSpacing={2}>
                    <Grid item sx={{ width: '100%'}} mb={4}>
                        <SubmitButton onButtonClick={onButtonClick}
                                      disabled={verificationCode.length !== 6}
                                      color="secondary"
                                      variant="contained"
                                      text="Continuer"
                                      iconAfter={loading ? <CircularProgress size="20px" /> : ''}/>
                    </Grid>
                </Grid>
            </Grid>
        </Box>

    );
}

export default MobileVerificationCode