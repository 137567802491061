import React, {useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import GameHeader from "../ui/gameHeader";
import StyledAvatar from "../customAvatar";
import OnChainScore from "../onChainScore";
import {AnimatePresence, Reorder, motion } from "framer-motion"
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import GameExplanation from "../gameExplanation";
import GameExplanationImageBachelor from '../../assets/explanation_chain_bachelor.svg';
import GameExplanationImageContender from '../../assets/explanation_chain_contender.svg';

function compareUsers(scores, users) {
    return [...users].sort((userA, userB) => {
        const scoreA = scores[userA.id] || 0; // Default score to 0 if not found
        const scoreB = scores[userB.id] || 0; // Default score to 0 if not found
        return scoreB - scoreA; // Sort in descending order
    });
}

// Composant
const ChainWouldYouRather = ({socket, data, initialDuration, players, isBachelor}) => {
    const [answer, setAnswer] = useState('');
    const [duration, setDuration] = useState(initialDuration);

    const [playerVoteChoice1, setPlayerVoteChoice1] = useState([]);
    const [playerVoteChoice2, setPlayerVoteChoice2] = useState([]);
    const [bachelorAnswer, setBachelorAnswer] = useState(null);
    const [choice1, setChoice1] = useState(data.question.choices[0]);
    const [choice2, setChoice2] = useState(data.question.choices[1]);
    const [scores, setScores] = useState(null);
    const [nbRep, setNbRep] = useState(0);
    const [usersData, setUsersData] = useState(players.contenders);

    const choice1Classes = `choice ${bachelorAnswer ? (bachelorAnswer === choice1.text ? "activeChoice" : "inactiveChoice" ) : answer === choice1 ? "activeChoice" : ""}`;
    const choice2Classes = `choice ${bachelorAnswer ? (bachelorAnswer === choice2.text ? "activeChoice" : "inactiveChoice" ) : answer === choice2 ? "activeChoice" : ""}`;

    useEffect(() => {
        console.log('bachelor', players?.bachelor);
        console.log('bachelor', players?.bachelor);

        socket.on('game_results', handleReceiveResults);

        return () => {
            socket.off('game_results', handleReceiveResults)
        }
    }, [choice1, choice2]);

    useEffect(() => {
        setPlayerVoteChoice1([]);
        setPlayerVoteChoice2([]);
        setBachelorAnswer(null);
        setAnswer('');

        setChoice1(data.question.choices[0]);
        setChoice2(data.question.choices[1]);
        setDuration(initialDuration);
    }, [data]);

    const handleSubmit = (choice) => {
        setAnswer(choice)

        socket.emit("game_response", { answer: choice.text });
    }

    const handleReceiveResults = (response) => {
        for(const key in response.ids) {
            if(key === choice1.text) {
                const urls = players.contenders.filter(user => response.ids[key].includes(user.id)).map(user => user.url);
                setPlayerVoteChoice1(urls);
            } else if(key === choice2.text) {
                const urls = players.contenders.filter(user => response.ids[key].includes(user.id)).map(user => user.url);
                setPlayerVoteChoice2(urls);
            }
        }

        if(response.final) {
            setBachelorAnswer(response.bachelorAnswer);
            setDuration(null)
            setScores(response.score);
            setNbRep(response.nbRep);
        }
    }
    // TODO: animation classement voir pk ca marche pas
    // Récupéré l'index des users pour savoir si leurs index change par rapport à l'ancien
    // const [prevUsers, setPrevUsers] = useState(users)
    // const prevUserIndex = prevUsers.findIndex((prevUser) => prevUser.id === user.id);
    // const isMoving = prevUserIndex !== -1 && prevUserIndex !== i;

    useEffect(() => {
        if(scores) {
            const newSortedUsers = compareUsers(scores, players.contenders);
            setUsersData(newSortedUsers);
        }
    }, [scores])


    return (
        <Box width={'100%'} height={"100%"}>
        <GameExplanation url={isBachelor ? GameExplanationImageBachelor : GameExplanationImageContender} />

        <Grid container className="twoChoices" flexDirection='column' justifyContent="space-around" alignItems="center" height="100%" margin="auto" wrap="nowrap">
            <Grid item flex="0 1" width="100%">
                <GameHeader duration={duration}
                            description={isBachelor ? "Test ta compatibilité avec l’élu ! Attention ne soit pas dernier au risque de te faire éliminer" : "Test ta compatibilité avec les prétendants"}
                            title="Test de compatibilité" players={players} />
            </Grid>

            <Grid item container flex="0 1 50%" width="90%" flexDirection="column" wrap="nowrap">
                <Grid item borderRadius="10px 10px 0 0" mb={2}
                      className={choice1Classes}
                    onClick={() => !answer && !bachelorAnswer ? handleSubmit(choice1) : ""}>
                    <Typography>{choice1.text}</Typography>

                    {bachelorAnswer ?
                        <Box className="vsBachelor">
                            <StyledAvatar src={data.bachelorUrl} sx={{width: 60, height: 60}}/>
                        </Box> :
                        <Box className="vs">
                            <Typography sx={{lineHeight: "50px"}}>VS</Typography>
                        </Box>
                    }

                    <Box sx={{position: "absolute", top: "3px", left: "3px", display: 'flex', justifyContent: 'flex-start'}}>
                        {playerVoteChoice1.map((url, i) => (
                            <StyledAvatar key={i} src={url} sx={{width: 30, height: 30}} style={{margin: '3px'}}/>
                        ))}
                    </Box>
                </Grid>

                <Grid item marginBottom="1em" borderRadius=" 0 0 10px 10px"
                      className={choice2Classes}
                      onClick={() => !answer && !bachelorAnswer ? handleSubmit(choice2) : ""} >
                    <Typography>{choice2.text}</Typography>

                    <Box sx={{position: "absolute", top: "3px", left: "3px", display: 'flex', justifyContent: 'flex-start'}}>
                        {playerVoteChoice2.map((url, i) => (
                            <StyledAvatar key={i} src={url} sx={{width: 30, height: 30}} style={{margin: '3px'}}/>
                        ))}
                    </Box>
                </Grid>
            </Grid>

            <Grid item className="classementAnimation" container flex="1 1 50%" gap={1} width="90%" flexDirection="column" wrap="nowrap" justifyContent="flex-start">
                 {/*<TransitionGroup gap={1}> */}
                {/*<AnimatePresence>*/}
                {/*<Reorder.Group as="div" values={usersData} onReorder={setUsersData}>*/}
                    {usersData.map((user, i) => (
                    // <CSSTransition key={i} classNames={isMoving ? 'fade-move' : 'fade'} timeout={500}>
                    //     <motion.div
                    //         key={i}
                    //         initial={{ opacity: 0, y: -20 }}
                    //         animate={{ opacity: 1, y: 0 }}
                    //         exit={{ opacity: 0, y: -20 }}
                    //         transition={{ duration: 0.5 }}
                    //     >
                    //     <Reorder.Item key={user.id} value={user.id}>
                            <OnChainScore
                            key={i}
                            classement={i + 1}
                            url={user.url}
                            name={user.name}
                            isLosing={scores && scores[user.id] === scores[usersData[usersData.length - 1].id]}
                            isGood={null}
                            score={scores ? scores[user.id] : 0}
                            maxRep={nbRep}
                            />
                        // </Reorder.Item>
                        // </motion.div>
                    // </CSSTransition>
                    ))}
                {/*</Reorder.Group>*/}
                {/*</AnimatePresence>*/}
                {/* </TransitionGroup> */}
            </Grid>

        </Grid>
        </Box>
    );
};

export default ChainWouldYouRather;