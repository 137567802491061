import React, {useEffect, useState} from "react";
import {Button, Divider, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import Answer, {TYPES} from "../answer";
import GameHeader from "../ui/gameHeader";
import Box from "@mui/material/Box";
import GameExplanation from "../gameExplanation";
import GameExplanationImageBachelor from '../../assets/explanation_sentence_bachelor.svg';
import SubmitButton from "../mui/submitButton";

// Composant
const SentenceEliminateWithChoice = ({socket, data: {suggestions, question, responses, canEliminate}, initialDuration, players}) => {
    const [answer, setAnswer] = useState([]);
    const [duration, setDuration] = useState(initialDuration);
    const [eliminationDone, setEliminationDone] = useState(false);
    const [isBlinking, setIsBlinking] = useState(false);
    const [customQuestion, setCustomQuestion] = useState('');

    useEffect(() => {
        socket.on('final_results', handleFinalAnswers);
        console.log("sugg", suggestions);

        return () => {
            socket.off('final_results', handleFinalAnswers);
        }
    }, []);

    useEffect(() => {
        if (answer.length === 2 && canEliminate) {
            socket.emit("game_response", { 'userIdsToKeep' : answer });
            setEliminationDone(true);
        }
    }, [answer]);

    useEffect(() => {
        setDuration(initialDuration);
    }, [canEliminate]);

    useEffect(() => {
        if(eliminationDone) {
            setDuration(null);
            setIsBlinking(true);
            const timer = setTimeout(() => {
                setIsBlinking(false);
            }, 2000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [eliminationDone]);

    const handleSelectResponse = (responseId) => {
        if(canEliminate) {
            if (answer.includes(responseId)) {
                // If the response is already selected, remove it from the answer
                setAnswer(answer.filter((id) => id !== responseId));
            } else if (answer.length < 2) {
                // If less than two responses are selected, add the response to the answer
                setAnswer([...answer, responseId]);
            }
        }
    };

    const handleFinalAnswers = (data) => {
        setAnswer(data.selectedUserIds);
        setEliminationDone(true);
    }

    const sendCustomQuestion = () => {
        socket.emit("question_response", { question : customQuestion, isCustom: true });
        setDuration(null);
    }

    const sendSuggestedQuestion = (suggested) => {
        console.log("sugssg", suggested);
        socket.emit("question_response", { question : suggested._id, isCustom: false });
        setDuration(null);
    }

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <GameExplanation url={GameExplanationImageBachelor} />
            <GameHeader duration={duration}
                        description={"Choisis une question à poser à tes prétendants \n Sélectionne ensuite tes 2 réponses préférées !"} title="Premier message" players={players} />

            { suggestions ?
                <Grid container className="eliminate" flexDirection='column' justifyContent="flex-start" gap={1} width="95%" margin="auto" wrap='nowrap' height="100%">
                    <Grid item container flex="0 1 50%" flexDirection="column" wrap="nowrap" gap={1}>
                        <Grid item>
                            <Typography>On te propose</Typography>
                        </Grid>

                        <Grid item className="choice" borderRadius="15px" onClick={() => sendSuggestedQuestion(suggestions[0])}>
                            <Typography variant="body1" textAlign="center">{suggestions[0].question}</Typography>
                        </Grid>

                        <Grid item className="choice" borderRadius="15px" onClick={() => sendSuggestedQuestion(suggestions[1])}>
                            <Typography textAlign="center">{suggestions[1].question}</Typography>
                        </Grid>
                    </Grid>

                    <Grid item container flex="0 1 50%" flexDirection="column" wrap="nowrap">
                        <Grid item>
                            <Divider>
                                <Typography variant="h6" textAlign="center">Ou</Typography>
                            </Divider>
                        </Grid>

                        <Grid item>
                            <Typography>Si tu as de l'inspiration</Typography>
                        </Grid>

                        <Grid item width="100%">
                            {/*<Typography variant="h6">Décris toi en quelques mots</Typography>*/}

                            <TextField size="small" fullWidth={true}
                                       value={customQuestion}
                                       onChange={(e) => setCustomQuestion(e.target.value)}
                                       color="secondary"
                                       multiline
                                       rows={3}
                                       InputProps={{
                                           style: {borderRadius: '10px'}
                                       }}
                            />
                        </Grid>

                        <Grid item mt='auto' mb={4} ml='auto' mr="auto">
                            <Button variant="activated"
                                    size="large"
                                    onClick={sendCustomQuestion}
                                    sx={{width: "100%", pl: 2, pr: 2}}
                                    disabled={!customQuestion}>
                                <Typography variant="body1" component="div" display="flex" gap="5px" alignItems="center">
                                    Poser la question aux prétendants
                                </Typography>
                            </Button>
                            {/*<SubmitButton text='Poser la question aux prétendants' variant="contained" color="gradient" onButtonClick={sendCustomQuestion} disabled={!customQuestion}/>*/}
                        </Grid>
                    </Grid>
                </Grid>
                :
                <Grid container className="eliminate" flexDirection='column' justifyContent="flex-start" alignItems="center" gap={2} width="95%" margin="auto" wrap='nowrap' height="100%">
                    <Grid item width="100%">
                        <Answer url={players.bachelor.url} sentence={question} name={players.bachelor.name} type={TYPES.Color}/>
                    </Grid>

                    <Grid item alignItems="center">
                        <Divider>
                            <Typography textAlign="center">{canEliminate ? "Sélectionne tes 2 réponses préférées ! " : `Attends que les prétendants répondent`}</Typography>
                        </Divider>
                    </Grid>

                    {/* Grille avec les réponse du prétendant */}
                    <Grid item container flexDirection='column' justifyContent="space-around" width={"100%"} height={'100%'} marginBottom={2}>
                        {responses?.map((object, i) => {
                            const user = players.contenders.find(user => user.id === object.id);
                            return (<Grid item key={i} width="100%" onClick={(e)=> handleSelectResponse(object.id)}>
                                <Answer url={user?.url} type={answer.includes(object.id) ? TYPES.Active : eliminationDone ? TYPES.Eliminate : TYPES.Normal}
                                        sentence={!object.answer ? canEliminate ? "Pas de réponse" : "" : object.answer} name={user?.name} isLoading={!object.answer && !canEliminate} isBlinking={isBlinking}/>
                            </Grid>);
                        })}
                    </Grid>
                </Grid>
            }
        </Box>
    );
};

export default SentenceEliminateWithChoice;