import React, {useEffect, useState} from "react";
import { auth } from '../firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import {theme} from "../theme";
import {Grid, ThemeProvider} from "@mui/material";
import Box from "@mui/material/Box";

import {IoIosArrowBack} from "react-icons/io";
import MobileNumber from "../components/mobileAuth/mobileNumber";
import MobileVerificationCode from "../components/mobileAuth/mobileVerificationCode";
import LinearProgress from "@mui/material/LinearProgress";

const NB_STEPS = 2;

const MobileAuth = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [messageStatus, setMessageStatus] = useState('');
    const [loading, setLoading] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState("");
    const [verificationCode, setVerificationCode] = useState("");

    useEffect(() => {
        const handlePopState = () => {
            if (activeStep >= 1) {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
            }
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, [activeStep]);

    const sendCodeToNumber = () => {
        if(loading)
            return;

        setLoading(true);

        const appVerifier = new RecaptchaVerifier('recaptcha-container', {
            size: "invisible", // Set the size to invisible
        }, auth);

        // const appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                window.history.pushState(null, "", "/auth");
                setActiveStep(1);
                setMessageStatus("");
            }).catch((error) => {
                console.log('err', error);
                setMessageStatus("Impossible de t'envoyer un sms");
        }).finally(() => {
                setLoading(false);
        });
    };

    const verifyCodeNumber = () => {
        if(loading)
            return;

        setLoading(true);

        window.confirmationResult.confirm(verificationCode).then((result) => {
        }).catch((error) => {
            console.log("error", error)
            setMessageStatus("Le code que tu as saisie est incorrect");
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleBack = () => {
        setMessageStatus("");
        window.history.back();
    };

    const handlePhoneNumberChange = (newValue) => {
        setPhoneNumber(newValue);
    };

    const handleVerificationCodeChange = (newValue) => {
        setVerificationCode(newValue);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box className="load contentWrapper" sx={{height: '100%'}}>
                <Grid container wrap='nowrap' direction='column' height='100%' width="100%" alignItems="center" justifyContent="space-between" rowSpacing={2}>
                    <Grid item sx={{ width: '100%'}}>
                        <LinearProgress color='tertiary' variant="determinate" value={(activeStep+1)/NB_STEPS * 100} />
                    </Grid>

                    <Grid item width="80%">
                        <IoIosArrowBack className="buttonGoBackward" size="35" color="#B650FA" onClick={handleBack}/>
                    </Grid>

                    <Grid item flex="1 1 auto" width="80%" display="flex">
                        {activeStep === 0 ?
                            <MobileNumber phoneNumber={phoneNumber} onPhoneNumberChange={handlePhoneNumberChange} onButtonClick={sendCodeToNumber} loading={loading} messageStatus={messageStatus}/>
                        :
                            <MobileVerificationCode phoneNumber={phoneNumber} verificationCode={verificationCode} onVerificationCodeChange={handleVerificationCodeChange} onButtonClick={verifyCodeNumber} loading={loading} messageStatus={messageStatus}/>
                        }
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
};

export default MobileAuth;