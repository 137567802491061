import React, {useEffect, useState} from "react";
import {Divider, Grid, Typography} from "@mui/material";
import Answer, {TYPES} from "../answer";
import GameHeader from "../ui/gameHeader";
import Box from "@mui/material/Box";
import AnswerInput from "../answerInput";
import GameExplanationImageContender from "../../assets/explanation_sentence_contender.svg";
import GameExplanation from "../gameExplanation";

const SentenceWithChoice = ({socket, initialDuration, players}) => {
    const [answer, setAnswer] = useState("");
    const [question, setQuestion] = useState(null);
    const [duration, setDuration] = useState(initialDuration);
    const [hasSubmit, setHasSubmit] = useState(false);
    const [responses, setResponses] = useState([]);
    const [isEliminating, setIsEliminating] = useState(false);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [isBlinking, setIsBlinking] = useState(false); // New state for blinking animation

    useEffect(() => {
        setAnswer("");

        socket.on('game_results', handleGameAnswers);
        socket.on('final_results', handleFinalAnswers);
        socket.on('update_question', handleUpdateQuestion);

        return () => {
            socket.off('game_results', handleGameAnswers);
            socket.off('final_results', handleFinalAnswers);
            socket.off('update_question', handleFinalAnswers);

        }
    }, []);

    useEffect(() => {
        // Trigger blinking animation when selectedUserId is updated
        if(selectedUserIds.length === 2) {
            // console.log('set isblinkin true')
            setIsBlinking(true);
            const timer = setTimeout(() => {
                setIsBlinking(false);
                // console.log('set isblinkin false')
            }, 2000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [selectedUserIds]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        socket.emit("game_response", { answer });
        setHasSubmit(true);
    }

    const handleGameAnswers = (data) => {
        setHasSubmit(true);
        setResponses(data.responses)
        setIsEliminating(data.isEliminating)
    }

    const handleFinalAnswers = (data) => {
        // console.log(data);
        setSelectedUserIds(data.selectedUserIds);
    }

    const handleUpdateQuestion = (data) => {
        console.log('update !', data);
        setQuestion(data.question);
        setDuration(data.duration);
    }

    return (
        <Box height="100%" display="flex" flexDirection="column" textAlign="center">
            <GameExplanation url={GameExplanationImageContender} />

            <GameHeader duration={question ? !hasSubmit ? duration : null : null}
                        description="Répond à la question posée par l’élu pour engager le début de conversation"
                        title="Premier message" players={players} />

            {/*{!hasSubmit ?*/}
            {/*    <GameHeader duration={question ? !hasSubmit ? duration : null : null} */}
            {/*                description="Répond à la question posée par l’élu pour engager le début de conversation" */}
            {/*                title="Premier message" players={players} />*/}
            {/*    :*/}
            {/*    <GameHeader duration={null} description={isEliminating ? `${players?.bachelor?.name} élimine quelqu'un ...` : "Attends que les prétendants répondent"} title="Élimination" players={players} />*/}
            {/*}*/}

            <Grid container className="sentence" flexDirection='column' justifyContent={!question ? "flex-start" : "space-between"} alignItems="center" width="95%" height="100%" margin="auto" wrap="nowrap" gap={2}>
                <Grid item>
                    <Answer url={players.bachelor.url} sentence={question ? question : ""} name={players.bachelor.name} type={TYPES.Color} isLoading={!question} />
                </Grid>

                {!question ?
                    <Grid item alignItems="center">
                        <Divider>
                            <Typography textAlign="center">{players?.bachelor?.name} sélectionne une question ...</Typography>
                        </Divider>
                    </Grid>
                : ''}

                {!hasSubmit ?
                    !question ? '' :
                    <Grid item container direction="row" alignItems="center" margin={2} color="white">
                        <Grid item width="100%">
                            <AnswerInput answer={answer} handleSubmit={handleSubmit}
                                         handleChange={(e) => setAnswer(e.target.value)}/>
                        </Grid>
                    </Grid>
                    :
                    <>
                        <Grid item alignItems="center">
                            <Divider>
                                <Typography textAlign="center">{isEliminating ? selectedUserIds.length > 0 ?  `${players?.bachelor?.name} à fait son choix !` : `${players?.bachelor?.name} choisit ses réponses préférees !` : "Attends que les prétendants répondent"}</Typography>
                            </Divider>
                        </Grid>

                        <Grid item container flexDirection='column' justifyContent="space-around" width={"100%"} height={'100%'} marginBottom={2}>
                            {responses.map((object, i) => {
                                const user = players.contenders.find(user => user.id === object.id);
                                return (<Grid item key={i} width="100%">
                                    <Answer url={user?.url} type={selectedUserIds.includes(object.id) ? TYPES.Active : selectedUserIds.length > 0 ? TYPES.Eliminate : TYPES.Normal}
                                            sentence={!object.answer ? isEliminating ? "Pas de réponse" : "" : object.answer} name={user?.name} isLoading={!object.answer && !isEliminating} isBlinking={isBlinking}/>
                                </Grid>);
                            })}
                        </Grid>
                    </>
                }
            </Grid>
        </Box>
    );
};

export default SentenceWithChoice;