import {store} from "../redux/store";
import {setMatches} from "../redux/match.slice";

export const getMatches = async (id, token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/match/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
        });

        if (!response.ok) {
            throw new Error("Erreur: Impossible de récupérer vos match");
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export const getMatchAndDispatchStore = (id, token) => {
    getMatches(id, token).then((data) => {
        console.log('user matches', data);
        store.dispatch(setMatches(data));

    })
};

export const getMessages = async (matchId, token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/message/${matchId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
        });

        if (!response.ok) {
            throw new Error("Erreur: Impossible de récupérer vos match");
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export const getGames = async (matchId, token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/session/games/${matchId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
        });

        if (!response.ok) {
            throw new Error("Erreur: Impossible de récupérer vos match");
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export const userHasAccount = async (userId, token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/hasAccount/${userId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
        });

        if (!response.ok) {
            throw new Error("Erreur: Impossible de récupérer vos match");
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};

export const deleteMatch = async (matchId, fromUserId, token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/match/delete`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({matchId, fromUserId}),
        });

        if (!response.ok) {
            throw new Error("Erreur: Impossible de récupérer vos match");
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};


export const signalMatch = async (matchId, fromUserId, token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/match/signal`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({matchId, fromUserId}),
        });

        if (!response.ok) {
            throw new Error("Erreur: Impossible de récupérer vos match");
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};