import {Modal} from "@mui/material";
import Box from "@mui/material/Box";
import UserCard from "./userCard";
import {useEffect} from "react";
import { motion } from "framer-motion";

const ProfilCard = ({handleClose, url, name, bio, age}) => {
    useEffect(() => {
        // console.log("show")
    }, [])

    return (
        <Modal
            open={true}
            onClose={handleClose}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box className='profilModal'>
                <motion.div
                    className='usercard'
                    initial={{ y: "100%" }}
                    animate={{ y: 0 }}
                    transition={{ type: "spring", stiffness: 450, damping: 40 }}
                    drag="y"
                    dragConstraints={{ top: 0, bottom: 0 }}
                    dragElastic={0.8}
                    onDragEnd={(event, { offset, velocity }) => {
                        if (offset.y > 100 || velocity.y > 500) {
                            handleClose();
                        }
                    }}
                >
                    <UserCard src={url} name={name} bio={bio} age={age} />
                </motion.div>
            </Box>
        </Modal>
    );
}

export default ProfilCard