import {createSlice} from "@reduxjs/toolkit";
import {ampli} from "../ampli";
import {getAgeFromBirth} from "../services/util";

const initialState = {
    loading: true,
    user: null,
    token: null,
    role: null,
}

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        loginUser: (state, {payload}) => {
            console.log("ss", payload);
            if(!payload.user.age && payload.user.birth) {
                payload.user.age = getAgeFromBirth(payload.user.birth);
            }

            state.user = payload.user;
            state.role = payload.role;
            state.token = payload.token;
            ampli.amplitude.setUserId(payload.user._id);
        },
        preLoginUser: (state, {payload}) => {
            state.user = {};
            state.role = payload;
        },
        logoutUser: (state, {payload}) => {
            state.user = null;
            state.role = null;
            state.token = null;
        },
        updateUserInfo: (state, {payload}) => {
            state.user = payload;
            state.user.age = getAgeFromBirth(payload.birth)
            //
        },
        setUserToken: (state, { payload }) => {
            state.token = payload;
        },
        setUserRole: (state, { payload }) => {
            state.role = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        }
    }
})

export const {
    loginUser,
    logoutUser,
    preLoginUser,
    setLoading,
    updateUserInfo
} = authSlice.actions;

export default authSlice.reducer;