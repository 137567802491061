import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    active: false,
    activeStep: 1,
    formData: {}
};

const registerSlice = createSlice({
    name: "register",
    initialState: initialState,
    reducers: {
        incrementStep: (state) => {
            state.activeStep += 1
        },
        decrementStep: (state) => {
            state.activeStep -= 1;
        },
        setFormData: (state, {payload}) => {
            state.formData = payload;
        },
    },
});

export const { incrementStep, decrementStep, setFormData } = registerSlice.actions;

export default registerSlice.reducer;
