import React from "react";
import {Grid, Typography} from "@mui/material";
import StyledAvatar from "./customAvatar";
import DotsAnimation from "./dotsAnimation";

export const TYPES = {
    'Normal': 1,
    'Color': 2,
    'Active': 3,
    'Eliminate': 4
}

const Answer = ({url, sentence, name, type, isLoading = false, isBlinking = false}) => {

    let className = type === TYPES.Active ? "activeAnswer" : type === TYPES.Color ? "colorAnswer" : type === TYPES.Eliminate ? "eliminateAnswer" : "";
    // className += isBlinking ? " blinking" : "";

    return (
        <Grid container flexDirection="column" margin="auto">
            <Grid item xs={12} gap={1} container flexDirection="row" alignItems={'flex-end'} justifyContent="space-between" flexWrap="nowrap">
                <Grid item marginLeft="5px">
                    <StyledAvatar src={`${url}`} sx={{ width: 50, height: 50 }}></StyledAvatar>
                </Grid>
                <Grid item container flexDirection="column">
                    <Grid item className={`answer ${className} ${isBlinking && type !== TYPES.Eliminate ? "blinking" : ""}`}>
                        <Typography variant="body1">{name}</Typography>
                        {isLoading ? <DotsAnimation /> : <Typography variant="body2" >{sentence}</Typography>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Answer