import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import StyledAvatar from "../customAvatar";
import {Grid, Typography, Chip, Button, IconButton, Avatar, Modal, CircularProgress} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import {getGroupData} from "../../services/group.service";
import {joinInvite, leaveInvite, setInviteId} from "../../redux/invite.slice";
import UserCard from "../userCard";
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import iconReady from '../../assets/icon_ready.png';
import iconNotReady from '../../assets/icon_notready.png';
import iconUserWaiting from '../../assets/user.png';
import Box from "@mui/material/Box";
import Header from "./header";
import {fetchCountdown} from "../../services/countdown.service";
import ProfilCard from "../profilCard";
import { ampli } from '../../ampli';
import {useNavigate} from "react-router-dom";

const Lobby = ({socket, socketConnected, deferredPrompt}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);
    const token = useSelector((state) => state.auth.token);
    const invite = useSelector((state) => state.invite);
    const {targetDate, isOn} = useSelector((state) => state.countdown);

    const [messageStatus, setMessageStatus] = useState('');
    const [sharedlink, setSharedlink] = useState('');
    const [isReady, setIsReady] = useState(false);
    const [isSearchingForRoom, setIsSearchingForRoom] = useState(false);
    const [showProfil, setShowProfil] = useState(false);

    const [friends, setFriends] = useState([]);
    const [preferencesGender, setPreferencesGender] = useState("Female");
    const [owner, setOwner] = useState('');
    const [nbPlayerWaiting, setNbPlayerWaiting] = useState(1);


    const [countdown, setCountdown] = useState(isOn ? new Date(targetDate).getTime() - new Date().getTime() : 0);

    // friend list is updated
    const updateFriends = (data) => {
        const {friends: updatedFriends} = data;

        if (updatedFriends.every(user => user.isReady)) {
            setIsSearchingForRoom(true);
        } else {
            setIsSearchingForRoom(false);
        }
        // console.log('update friend event:', updatedFriends);
        setFriends(updatedFriends);
    };

    const updatePreferences = (data) => {
        // console.log("update pref", data)
        const {gender} = data;
        setPreferencesGender(gender);
    };

    const updateOwner = (data) => {
        // console.log("receive owner", data)
        const {owner: newOwner} = data;
        setOwner(newOwner);
    };

    const updateInfoPlayers = ({nbPlayer}) => {
        setNbPlayerWaiting(nbPlayer);
    }

    // Fetch group data when user join the page
    useEffect(() => {
        if (invite.inviteId && invite.alreadyJoined) {
            getGroupData(invite.inviteId, user._id, token).then((response) => {
                if(!response)
                    return;
                const {friends: newFriends, preferences: newPreferences, owner: newOwner} = response;
                setFriends(newFriends);
                setPreferencesGender(newPreferences.gender);
                setOwner(newOwner);
            })
        } else {
            setPreferencesGender(user.gender === 'Female' ? "Male" : "Female");
        }
    }, []);


    useEffect(() => {
        let interval;

        if (isOn && targetDate) {
            interval = setInterval(() => {
                // console.log('interval');
                const newCountdown = new Date(targetDate).getTime() - new Date().getTime();
                setCountdown(newCountdown);
                if (newCountdown < 0) {
                    fetchCountdown(dispatch);
                }
            }, 1000);
        } else {
        }

        return () => {
            // console.log("clear interval");
            clearInterval(interval);
        };
    }, [isOn, targetDate]);

    function formatCountdown(countDown) {
        // calculate time left
        const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

        let resp = days > 0 ? `${days} jours ` : '';
        resp += hours > 0 ? `${hours} heures ` : '';
        resp += minutes > 0 ? `${minutes} minutes ` : '';
        resp += `${seconds} secondes `;
        return resp;
    }


    // TODO : check what it does when the socket connect, disconnect and reconnect
    useEffect(() => {
        if (invite.inviteId && !invite.alreadyJoined) {
            socket.emit('join_friends', {inviteId: invite.inviteId}, (response) => {
                if (!response.status) {
                    setMessageStatus(response.message);
                } else {
                    setPreferencesGender(response.gender);
                    dispatch(joinInvite());
                    // console.log("joined group");
                }
            });
        }

        if(!socketConnected) {
            setIsSearchingForRoom(false);
        }

        socket.on('preferences_updated', updatePreferences);
        socket.on('update_friends', updateFriends);
        socket.on('owner_updated', updateOwner);
        socket.on('info_players', updateInfoPlayers);

        return () => {
            // console.log("delete event groups");
            socket.off('update_friends', updateFriends);
            socket.off('preferences_updated', updatePreferences);
            socket.off('owner_updated', updateOwner);
            socket.off('info_players', updateInfoPlayers);

        };
    }, [socketConnected]);


    const handlePreferenceChanged = (event) => {
        const newGender = event.target.value;
        setPreferencesGender(newGender);
        if (invite.inviteId) {
            socket.emit("update_preferences", {gender: newGender});
        }

    }

    // User without group click on play
    const handlePlay = () => {
        console.log("play")
        if (!isSearchingForRoom) {
            socket.emit("search_for_room", {}, (response) => {
                if (response.status) {
                    setIsSearchingForRoom(true);
                    ampli.playGame({isInGroup: friends.filter(friend => friend.id !== user._id).length > 0, groupId: invite.inviteId, search: true});
                } else {
                    setMessageStatus("Une erreur est survenue.")
                }
            });
        } else {
            socket.emit("stop_search_for_room");
            ampli.playGame({isInGroup: friends.filter(friend => friend.id !== user._id).length > 0, groupId: invite.inviteId, search: false});
            setIsSearchingForRoom(false);
        }
    }

    // When user is in group and click on ready
    const handleReady = () => {
        const tmpIsReady = isReady;
        // console.log("handleReady before changing value", tmpIsReady);
        socket.emit("friend_set_ready", {ready: !tmpIsReady});
        setIsReady(!tmpIsReady);
    }

    // User leaves the group
    const handleLeaveGroup = () => {
        socket.emit('leave_group');
        socket.off('update_friends', updateFriends);
        socket.off('preferences_updated', updatePreferences);
        socket.off('owner_updated', updateOwner);

        setFriends([])
        setIsReady(false);
        dispatch(leaveInvite());
        setSharedlink('');
    }

    // Generate shareable link
    const handleInviteFriends = () => {
        // console.log("invite friends");
        if (sharedlink) {
            shareLink(sharedlink)
        } else {
            socket.emit('invite_friends', {gender: preferencesGender}, (response) => {
                // console.log(response)
                if (response.status) {
                    const link = `${window.location.origin}?invite=${response.inviteId}`;
                    setSharedlink(link);
                    if (!invite.inviteId) {
                        dispatch(setInviteId(response.inviteId));
                        dispatch(joinInvite());
                    }
                    shareLink(link);
                }
            });
        }

        ampli.inviteFriends({groupId: invite.inviteId});
    }

    function shareLink(link) {
        if (navigator.share) {
            navigator
                .share({
                    title: 'Bliss',
                    text: 'Rejoins moi sur Bliss',
                    url: link,
                }).catch((err) => {
                // console.log(err);
            });
        } else {
            setMessageStatus(`Partagez ce lien : ${link}`)
            // fallback code
            console.log(
                "Web share is currently not supported on this browser. Please provide a callback"
            );
        }
    }

    return (
        // Box pour prendre toute la page
        <Box wrap='nowrap' height="100%" width="100%">
            {/* Grid avec les 3 composents "header" body "footer" */}
            <Grid container flexDirection='column' justifyContent="space-between" alignItems="center"
                  height='100%' wrap='nowrap'>
                {/*<Grid item width="100%">*/}
                {/*    <InstallPWA full={false} deferredPrompt={deferredPrompt}/>*/}
                {/*</Grid>*/}
                {/* Headers */}
                <Grid item width="100%">
                    <Header/>
                </Grid>

                {/* Contenue de la page */}
                <Grid container item rowGap={1} flexDirection='column' justifyContent="flex-start" alignItems="center"
                      height='100%' wrap='nowrap' margin={'auto'}>
                    {/* barre de recherche avec chips */}
                    {/*<Grid item container direction="row" justifyContent={"flex-start"} alignItems={"center"}*/}
                    {/*      sx={{my: 0}}>*/}
                    {/*    /!* Recherche *!/*/}
                    {/*    <Grid item xs={3}>*/}
                    {/*        <Typography>*/}
                    {/*            Préférence :*/}
                    {/*        </Typography>*/}
                    {/*    </Grid>*/}
                    {/*    /!* Chip mode *!/*/}
                    {/*    <Grid item xs={3}>*/}
                    {/*        <Chip label='Mode 5:1' variant="outlined" size="small"*/}
                    {/*              sx={{width: "100%", color: '#BBBBBB'}}*/}
                    {/*        />*/}

                    {/*    </Grid>*/}
                    {/*    /!* Chip age *!/*/}
                    {/*    <Grid item xs={3}>*/}
                    {/*        <Chip label='Age' variant="outlined" size="small" sx={{width: "100%", color: '#BBBBBB'}}*/}
                    {/*        />*/}
                    {/*    </Grid>*/}
                    {/*    /!* Chip sexe *!/*/}
                    {/*    <Grid item xs={3}>*/}
                    {/*        <select value={preferencesGender} disabled={!invite.inviteId || (invite.inviteId && owner !== user._id)}*/}
                    {/*                onChange={handlePreferenceChanged}>*/}
                    {/*            <option value="Male">Homme</option>*/}
                    {/*            <option value="Female">Femme</option>*/}
                    {/*        </select>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}

                    {/*<Grid item className="infobanner" width="100%">*/}
                    {/*    <Typography color="primary" variant="caption" fontSize="0.8em">🎉 Friends night : match avec les 2 sexes 🎉</Typography>*/}
                    {/*</Grid>*/}

                    {/* Début du composant carte */}
                    <Grid item sx={{width: '95%', aspectRatio: '3/4'}}>
                        <Grid onClick={() => setShowProfil(true)} style={{position: "relative", height: '100%'}}>
                            <UserCard onClick={() => setShowProfil(true)}
                                bio={user.bio} name={user.name} src={user.pictures[0]} age={user.age}>
                                {countdown > 0 ?
                                    <Button variant="activated" sx={{ width: "100%", height: '100%'}}> {formatCountdown(countdown)}</Button>
                                    : friends.filter(friend => friend.id !== user._id).length > 0 ?
                                    <Button variant={isReady ? "activated" : "activated"} onClick={handleReady}
                                        sx={{
                                            width: "100%",
                                            height: '100%'
                                        }} >
                                        {isSearchingForRoom ?
                                            <>
                                                <Typography color="primary" variant="body2" display="flex" alignItems="center" gap="5px">Recherche <CircularProgress size="18px" /></Typography>
                                                <Typography color="primary" variant="body1" sx={{position: 'absolute', right: "25px"}} alignItems="center" display="flex" gap="3px">{nbPlayerWaiting} <img src={iconUserWaiting} alt="icon personnes en attente" width="18px" height="auto"/></Typography>
                                            </>
                                            : "Prêt"}
                                        {/*{isSearchingForRoom ? <> {nbPlayerWaiting} </> : "Prêt"}*/}
                                        <Box sx={{position: "absolute", top: '-5px', right: '-5px'}}>{isReady ?
                                            <img src={iconReady} alt="ready"/> :
                                            <img src={iconNotReady} alt="not ready"/>}
                                        </Box>
                                    </Button>
                                    :
                                    <Button variant={isSearchingForRoom ? "triggered" : "activated"} onClick={handlePlay}
                                        sx={{
                                            width: "100%",
                                            height: '100%',
                                            position: 'relative'
                                        }} >
                                        {/*{isSearchingForRoom ? <> Recherche <DotsAnimation/> </> : "Jouer"}*/}
                                        {isSearchingForRoom ?
                                            <>
                                                <Typography color="primary" variant="body2" display="flex" alignItems="center" gap="5px">Recherche <CircularProgress size="18px" /></Typography>
                                                <Typography color="primary" variant="body1" sx={{position: 'absolute', right: "25px"}} alignItems="center" display="flex" gap="3px">{nbPlayerWaiting} <img src={iconUserWaiting} alt="icon personnes en attente" width="18px" height="auto"/></Typography>
                                            </>
                                            : "Jouer"}
                                    </Button>
                                }
                            </UserCard>

                            {/*<Box sx={{position: "absolute", top: '10px', right: "10px", maxHeight:"100%" ,height: "70%"}}>*/}
                            {/*    <Grid container columns={5} direction={"column-reverse"} justifyContent={'flex-end'} sx={{maxHeight:"100%" ,maxwidth:"40%",position: "relative"}}>*/}
                            {/*    */}
                            {/*    {friends.filter(friend => friend.id !== user._id).length < 5 ?*/}
                            {/*    <Grid id='InviteGrid' container item direction={"column"} xs={1} sx={{minWidth:'100%',maxHeight:'100%'}} alignItems={"center"} justifyContent='center' onClick={(e) => e.stopPropagation()}>*/}
                            {/*        <IconButton onClick={handleInviteFriends} sx={{ height: '50px', width: '50px', textAlign: 'center'}}>*/}
                            {/*        <Avatar sx={{backgroundImage: 'linear-gradient(145deg, #F02FFD 6.25%, #7873F5 92.75%)' , height: '50px', width: '50px', textAlign: 'center'}}>*/}
                            {/*            <PersonAddIcon sx={{height: '50%' , width: '50%'}}/>*/}
                            {/*        </Avatar>*/}
                            {/*        </IconButton>*/}

                            {/*        <Typography variant='body1' color="#FFFFFF" textAlign="center"> Invite </Typography>*/}
                            {/*    </Grid>*/}
                            {/*    : ''}*/}

                            {/*    {friends.filter(friend => friend.id !== user._id).map((friend) => (*/}
                            {/*        <Grid item xs={1} textAlign="center" justifyContent="center" key={friend.id} sx={{position: "relative" ,width: '100%',height:"20%"}}>*/}
                            {/*            /!* TODO :Faire en sorte que les custom avatars soit responsive avec des box de 20% de width *!/*/}
                            {/*            /!* TODO : Centré les avatars avec les noms quand ils sosnt long*!/*/}
                            {/*            <StyledAvatar src={friend.url} textAlign="center" sx={{width: '50px', height: '50px'}}/>*/}
                            {/*            <Typography variant='body1' color="#FFFFFF"*/}
                            {/*                        textAlign="center">{friend.name}</Typography>*/}
                            {/*            <Box sx={{position: "absolute", top: '-5px', right: '-5px'}}>{friend.isReady ?*/}
                            {/*                <img src={iconReady} alt="ready"/> :*/}
                            {/*                <img src={iconNotReady} alt="not ready"/>}</Box>*/}
                            {/*        </Grid>*/}
                            {/*    ))}*/}
                            {/*    <Grid item xs sx={{minheight:'100%'}}>*/}
                            {/*    </Grid>*/}

                            {/*    </Grid>*/}
                            {/*</Box>*/}
                            {friends.filter(friend => friend.id !== user._id).length > 0 ?
                                <IconButton sx={{position: "absolute", top: '10px', left: "10px"}}
                                            aria-label="Leavegroup" size="large"
                                            onClick={(e) => {e.stopPropagation(); handleLeaveGroup()}}
                                    // TODO : Mettre un modal pour avertir que tu quitte le groupe
                                >
                                    <LogoutIcon/>
                                </IconButton> : ''}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Typography variant="body2" color="secondary">{messageStatus}</Typography>
                </Grid>

                {/*<Grid item>*/}
                {/*    <Button variant="contained" color="secondary" onClick={() => navigate('/auth')}>creer un compte</Button>*/}
                {/*</Grid>*/}

                {showProfil &&
                    <ProfilCard handleClose={() => setShowProfil(false)}
                                bio={user.bio} name={user.name} url={user.pictures[0]} age={user.age}/>
                }

            </Grid>
        </Box>
    );
};

export default Lobby;