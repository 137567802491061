import React  from 'react';
import {Box, TextareaAutosize, IconButton} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

const AnswerInput = ({ answer, handleChange, handleSubmit, maxLength = 1000, disabled = false }) => {
  return (
      // <form onSubmit={handleSubmit} className="longAnswerInput">
      <Box className="longAnswerInput">
          <TextareaAutosize
              disabled={disabled}
              className="answerTextarea"
              minRows={1}
              maxRows={4}
              maxLength={maxLength}
              placeholder="Entrer une réponse"
              value={answer}
              onChange={handleChange}
              sx={{border:'none', width: "100%", wordWrap: "break-word" ,margin:'0px'}}
          />
          {/*<input*/}
          {/*    type="text"*/}
          {/*    className="answerInput"*/}
          {/*    placeholder="Enter a response"*/}
          {/*    value={answer}*/}
          {/*    onChange={handleChange}*/}
          {/*    style={{*/}
          {/*        border: 'none',*/}
          {/*        width: '100%',*/}
          {/*        wordWrap: 'break-word',*/}
          {/*        margin: '0px',*/}
          {/*        minHeight: '25px', // Minimum height for single line*/}
          {/*        lineHeight: '1.5', // Line height for better readability*/}
          {/*    }} />*/}
          <Box alignItems='flex-end'>
              <IconButton disabled={!answer || disabled} onClick={handleSubmit} className="roundIconButton" sx={{ backgroundImage: 'linear-gradient(145deg, #F02FFD 6.25%, #7873F5 92.75%)', borderRadius: "15px", alignSelf: 'flex-end' }}>
                  <SendIcon sx={{ color: 'white', fontSize: 'inherit' }} />
              </IconButton>
          </Box>
      </Box>
      // </form>
  );
};

export default AnswerInput;
