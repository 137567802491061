import React from "react";
import {useNavigate} from "react-router-dom";
import {auth, googleProvider, facebookProvider} from "../firebase";
import {ampli} from "../ampli";

import {theme} from "../theme";
import Box from "@mui/material/Box";
import {Grid, ThemeProvider, Typography} from "@mui/material";

import SubmitButton from "../components/mui/submitButton";
import IconBliss from '../assets/whiteicon_bliss.png';
// import {FaFacebook} from "react-icons/fa";
import InstallPWA from "../components/installPWA";
// import {createNewGuestUser, createNewUser} from "../services/auth.service";
import {preLoginUser} from "../redux/auth.slice";
import {ROLES} from "../App";
import {useDispatch} from "react-redux";

const Join = ({deferredPrompt}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleFacebookSignIn = async () => {
        ampli.joinButtonClicked({button: 'facebook'});
        await auth.signInWithRedirect(googleProvider);
    };

    const handleMobileSignIn = async () => {
        ampli.joinButtonClicked({button: 'mobile'});
        navigate('/auth');
    }

    const handleGuestSignIn = async () => {
        ampli.joinButtonClicked({button: 'guest'});
        dispatch(preLoginUser(ROLES.NewGuestUser));
        navigate('/register');

        // createNewGuestUser().then((response) => {
        //     console.log(response);
        //     dispatch(loginUser({user: response.user, token: response.token, role: ROLES.NewGuestUser}));
        //     navigate('/mobile');
        //
        // }).catch((err) => {
        //     console.log("err", err);
        // });
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{height: '100%', bgcolor: theme.palette.tertiary.main}} className="contentWrapper">
                <Grid container spacing={1} wrap='nowrap' direction='column' height='100%' alignItems="center" width="80%" margin="auto" justifyContent="space-between">
                    <Grid item container flex="0 1 50%" display='flex' alignItems="center" direction='column' justifyContent="space-around">
                        <Grid item display="flex" alignItems="center" gap={1}>
                            <img src={IconBliss} alt="icon bliss" height="auto" width="32px"/>
                            <Typography color="primary" variant="h4">bliss</Typography>
                        </Grid>

                        <Grid item>
                            <Typography color="primary" variant="h3" textAlign="center">Le premier jeu de rencontre</Typography>
                        </Grid>

                        <Grid item>
                            <Typography variant="h6" color="primary" textAlign="center">Change ta façon de faire des rencontres</Typography>
                        </Grid>
                    </Grid>

                    <Grid item container>
                        {/*<Grid item xs={12} margin="auto" mt='1em'>*/}
                        {/*    <SubmitButton text="Continuer avec Google" variant="contained" color="secondary" onButtonClick={handleGoogleSignIn} iconBefore={<FcGoogle size={20}/>}/>*/}
                        {/*</Grid>*/}

                        {/*<Grid item xs={12} margin="auto" mt='1em'>*/}
                        {/*    <SubmitButton text="Continuer avec Facebook" variant="contained" color="secondary" onButtonClick={handleFacebookSignIn} iconBefore={<FaFacebook size={20}/>}/>*/}
                        {/*</Grid>*/}

                        {/*<Grid item xs={12} margin="auto" mt='1em'>*/}
                        {/*    <InstallPWA deferredPrompt={deferredPrompt} full={false} />*/}
                        {/*    /!*<SubmitButton text="Télécharger l'app" variant="contained" color="secondary" onButtonClick={handleDownloadApp}/>*!/*/}
                        {/*</Grid>*/}

                        <Grid item xs={12} margin="auto" mt='1em'>
                            {/*<SubmitButton text="Utiliser un numéro de tél." variant="contained" color="primary" onButtonClick={handleMobileSignin} />*/}
                            <SubmitButton text="Jouer" variant="contained" color="primary" onButtonClick={handleMobileSignIn} />
                        </Grid>

                        <Grid item xs={12} margin="auto" mt='1em' onClick={handleGuestSignIn} visibility="hidden">
                            <Typography color="primary" variant="caption" component="p" textAlign="center">Continuer en tant qu'invité</Typography>
                        </Grid>

                        <Grid item mt={4} mb={4}>
                            <Typography color="primary" variant="body2" textAlign="center" lineHeight="1.6">En t'inscrivant, tu acceptes nos <a href="https://magnetic-queen-4a8.notion.site/Conditions-g-n-rales-d-utilisation-90ff1e5e82e34766a928ee434737b8a5?pvs=4" target="_blank" className="cgvLink">Conditions générales</a>. Pour plus d'infos sur notre façon d'utiliser tes données, consulte notre <a href="https://magnetic-queen-4a8.notion.site/Politique-de-confidentialit-5ee5b9e2c8254ddc9609243e3a225655?pvs=4" target="_blank" className="cgvLink">Politique de Confidentialité</a></Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
};

export default Join;