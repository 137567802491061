import React from "react";

import AddPhotoIcon from '@mui/icons-material/AddAPhoto'
import Box from "@mui/material/Box";

const UploadPicture = ({imageObject, alternativePic, onPictureChange, disabled=false}) => {
    return (
        <Box component="label"
                sx={{
                    width: "100%",
                    aspectRatio:"1/1",
                    alignItems: 'center',
                    borderRadius: '10px',
                    background: imageObject ? `url(${imageObject}) center no-repeat` : alternativePic ? `url(${alternativePic}) center no-repeat` : !disabled ? 'rgba(0,0,0,0.1)' : 'var(--inactive)',
                    border: `1px solid #DADADAFF`,
                    boxSizing: 'border-box',
                    color : disabled ? '#999999' : '#999999',
                    backgroundSize: 'cover',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
            {imageObject || alternativePic ? '' : <AddPhotoIcon sx={{width:'25%' ,height:'auto'}}/>}
            <input type="file" id="image" accept="image/*" onChange={onPictureChange} hidden disabled={disabled}/>
        </Box>
    );
}

export default UploadPicture