import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';

const CustomAvatar = styled(Avatar)(({ theme }) => ({
    // display: 'inline-block',
    // padding: '2px',
    // borderRadius: '50%',
    // border: 'solid 1px #7B49FF'
}));

export default function StyledAvatar({onClick, src, sx, style}) {
    return (
        <div className="avatarBorder" style={style} onClick={onClick}>
            <CustomAvatar src={src} sx={sx}/>
        </div>
    )
}