import * as React from 'react';
import {Card, CardActions, Typography} from "@mui/material";
import Box from "@mui/material/Box";

export default function UserCard({name, bio, src, age, children}) {
    return (
        <Card sx={{borderRadius: "20px", display: 'flex', flexDirection: 'column', height: '100%',bgcolor: '#2E2E2E'}}>
            <Box sx={{ position: "relative", flex: '1 1 90%'}}>
                {/*<CardMedia*/}
                {/*           component="img"*/}
                {/*           image={src ? src : src}*/}
                {/*           alt="User UploadPicture"/>*/}
                <Box sx={{background: `url(${src}) no-repeat`,
                    height: "100%",
                    width: '100%',
                    WebkitMaskImage :"linear-gradient(rgba(0, 0, 0, 1) 70%,transparent)",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'}}>
                </Box>
                <Box  sx={{position: "absolute",
                    color: "white",
                    bottom: 10,
                    left: "10px"}}>

                    <Typography variant="h5">{name}, {age} ans</Typography>
                    <Typography variant="body1">{bio}</Typography>
                </Box>
            </Box>
            <CardActions sx={{ flex: '1 1 10%', display: 'flex', justifyContent:'center'}} onClick={(e) => e.stopPropagation()}>
            {children}
            </CardActions>
        </Card>
    )
}