import React from "react";
import {Button, Grid, Typography} from "@mui/material";

import Box from "@mui/material/Box";

import {FaInfoCircle} from 'react-icons/fa';
import SubmitButton from "../mui/submitButton";

const RegisterGender = ({gender, onGenderChange, onButtonClick}) => {
    return (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
            <Grid container wrap='nowrap' direction="column" rowSpacing={2}>
                <Grid item mb={1}>
                    <Typography variant="h4">A quel genre tu t'identifies ?</Typography>
                </Grid>
                <Grid item width="100%" textAlign="center">
                    <Button variant={gender === 'Female' ? "activated" : "outlined"} color="secondary" onClick={() => onGenderChange('Female')} sx={{ width: "100%" }}>
                        <Typography variant="button" component="p">Femme</Typography>
                    </Button>
                </Grid>
                <Grid item width="100%" textAlign="center">
                    <Button variant={gender === 'Male' ? "activated" : "outlined"} onClick={() => onGenderChange('Male')} color="secondary" sx={{ width: "100%" }}>
                        <Typography variant="button" component="p">Homme</Typography>
                    </Button>
                </Grid>
            </Grid>

            <Grid container wrap='nowrap' direction="column" rowSpacing={3}>
                <Grid item>
                    <Box className="registerinfo">
                        <FaInfoCircle size={25} />
                        <Typography variant="caption">Il n'est pas encore possible de sélectionner un autre genre. Mais ça arrive !</Typography>
                    </Box>
                </Grid>
                <Grid item sx={{ width: '100%'}} mb={4}>
                    <SubmitButton text='Continuer' variant="contained" color="secondary" onButtonClick={onButtonClick} disabled={gender === ''}/>
                </Grid>
            </Grid>
        </Box>

    );
}

export default RegisterGender