import React from 'react'
import {
    Box,
    Button,
    Stack, Typography,
} from '@mui/material'
import Title from './Title'
import { Link } from 'react-router-dom'
import AppStoreDL from "../../assets/appstore_dl.svg";
import PlayStoreDL from "../../assets/google-play-badge.png";

const Download = () => {
    return (
        <Stack
        component='section'
        className="bloc section-white"
        direction="column"
        justifyContent= 'center'
        alignItems='center'
        sx={{
            background: 'var(--gradient)'
        }}
        >
            <Title
                text={
                    'Trouve ton prochain crush maintenant'
                }
                textAlign={'center'}
                color="primary"
            />
            <Typography textAlign="center" variant="" color="primary" py={3}>Télécharge gratuitement Bliss</Typography>
            {/*TODO*/}
            <Box component={Link} to="/join" sx={{display: 'flex', gap: 1, justifyContent: "center", flexDirection: {xs: 'column', xsm: 'row'}}}>
                <Box component={Link} to="/join">
                    <img src={AppStoreDL} alt="bouton de téléchargement app store"/>
                </Box>
                {/*<Box component={Link} to="/join">*/}
                {/*    <img src={PlayStoreDL} alt="bouton de téléchargement play store" width="auto" height="40px" />*/}
                {/*</Box>*/}
            </Box>
            {/*<Button component={Link} */}
            {/*to={'/join'}*/}
            {/*variant="contained" */}
            {/*type="submit"*/}
            {/*size="medium"*/}
            {/*sx={{*/}
            {/*    "&&:hover": {*/}
            {/*        backgroundColor: "var(--secondary)",*/}
            {/*        color: 'white'*/}
            {/*    },*/}
            {/*    px: 2*/}
            {/*}}*/}
            {/*>*/}
            {/*    <Typography variant="body1" p={0.5}>*/}
            {/*        Télécharger*/}
            {/*    </Typography>*/}

            {/*</Button>*/}
 
        </Stack>
    )
}

export default Download;