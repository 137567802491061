import {useDispatch, useSelector} from "react-redux";
import Navbar from "../components/ui/navbar";
import React, {useState} from "react";
import {auth} from "../firebase";
import {logoutUser, updateUserInfo} from "../redux/auth.slice";
import Box from "@mui/material/Box";
import Header from "../components/ui/header";
import {
    Button,
    Grid,
    Stack,
    TextField,
    ThemeProvider,
    Typography,
    List,
    InputAdornment, Modal
} from "@mui/material";
import {deleteUser, updateUser} from "../services/user.service";

import {theme} from "../theme";
import ListInfo from "../components/listInfo";

import {ampli} from '../ampli';
import UploadPicture from "../components/account/uploadPicture";
import {compressAndUpload} from "../services/util";
import Loader from "../components/loader";


const Account = ({socket}) => {
    const dispatch = useDispatch();
    const {user, token, role} = useSelector((state) => state.auth);

    const [messageStatus, setMessageStatus] = useState('');
    const [needUpdate, setNeedUpdate] = useState(false);
    const [needUpload, setNeedUpload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [deleteError, setDeleteError] = useState("");

    const [bio, setBio] = useState(user.bio);
    const [imageObject, setImageObject] = useState(user.pictures[0]);
    const [image, setImage] = useState('');
    const [imageUrl, setImageUrl] = useState(user.pictures[0]);
    const [gender, setGender] = useState(user.gender);
    const [name, setName] = useState(user.name);
    const [characterCount, setCharacterCount] = useState(user.bio.length);

    // Pour list
    // const [characteristic, setCharacteristic] = useState('Ajouter');
    // const [profession, setProfession] = useState('Ajouter');
    // const [location, setLocation] = useState('Ajouter');
    // const [interests, setInterests] = useState('Ajouter');
    // const [seeking, setSeeking] = useState('Ajouter');

    const handleSignout = () => {
        auth.signOut().then(() => {
            socket.emit('logout');
            socket.disconnect();
            dispatch(logoutUser());
            ampli.signOut();
        }).catch((err) => {
            console.log(err)
            // alert(err.message)
        });
    }

    const areInputsComplete = () => {
        return name && gender && imageObject;
    };

    const cancelUpdateInfo = async () => {
       setBio(user.bio);
       setImageObject(user.pictures[0]);
       setImage('');
       setImageUrl(user.pictures[0]);
       setGender(user.gender);
       setName(user.name);
       setCharacterCount(user.bio.length);
       setNeedUpdate(false);
    }

    const update = async (url = imageUrl) => {
        const userData = {name, bio, pictures: [url], gender}
        console.log(imageUrl);
        console.log(url);
        const newUser = await updateUser(user._id, userData, token);
        if (newUser) {
            setMessageStatus('Informations mises à jour');
            dispatch(updateUserInfo(newUser));
            setNeedUpdate(false);
        } else {
            setMessageStatus('Une erreur est survenue. Impossible de mettre à jour vos informations');
        }
    }

    const handleUpdateUser = async () => {
        if(name === '' || imageObject === '' || gender === '')
            return;

        if(needUpload) {
            setLoading(true);
            compressAndUpload(image).then((url) => {
                console.log(url);
                setImageUrl(url);
                setNeedUpload(false);
                update(url);
            }).finally(() => {
                setLoading(false);
            });
        } else {
            update();
        }
    }

    const hasChanged = () => {
        setNeedUpdate(true);
    }

    const handleDeleteAccount= () => {
        setLoading(true);
        setOpenModal(false);
        deleteUser(user._id, token).then((response) => {
           handleSignout();
        }).catch(err => {
            setDeleteError(err.toString());
            setOpenModal(true);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <ThemeProvider theme={theme}>
            <Box height="100%" width="100%" margin="auto" className="contentWrapper">
                {loading ? <Loader visible={true} /> : ""}
                <Grid container flexDirection='column' justifyContent="space-between" alignItems="center"
                      height='100%' wrap='nowrap' id="accModal">

                    <Grid item>
                        <Header/>
                    </Grid>

                    {/* Contenue de la page */}
                    <Grid item className='scrollable' width={"90%"}>
                        <Stack spacing={3} direction="column">
                            {needUpdate ?
                                <Box display='flex' gap={2} justifyContent='space-between' position="sticky" top="5px" zIndex="5">
                                    <Button flex="0 0 50%" variant="contained" fullWidth={true} onClick={cancelUpdateInfo}>
                                        <Typography variant="body1">Annuler</Typography>
                                    </Button>
                                    <Button flex="1 1 50%" variant="activated" fullWidth={true} onClick={handleUpdateUser} disabled={!areInputsComplete()}>
                                        <Typography variant="body1">Sauvegarder</Typography>
                                    </Button>
                                </Box>
                            : ''}

                            <Box display="flex" flexDirection="column" gap={0.5}>
                                <Typography variant="h5" fontWeight="bold">
                                    Photos
                                </Typography>
                                <Typography variant="subtitle1" lineHeight="normal" pb={1}>
                                    Les photos qui te représentent le mieux
                                </Typography>

                                <Box display="flex" justifyContent="space-between" gap={1}>
                                    <Box flex="0 0 65%">
                                        <UploadPicture imageObject={imageObject} alternativePic={user.pictures[0]} onPictureChange={(e) => {
                                            setImage(e.target.files[0]);
                                            if (e.target.files[0])
                                                setImageObject(URL.createObjectURL(e.target.files[0]));
                                            hasChanged();
                                            setNeedUpload(true);
                                        }} />
                                    </Box>
                                    <Box display="flex" flexDirection="column" flex="0 0 30%" justifyContent="space-between" alignItems="center">
                                        <Box>
                                            <UploadPicture disabled={true}/>
                                        </Box>
                                        <Box>
                                            <UploadPicture disabled={true}/>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box display="flex" flexDirection="column" gap={0.5}>
                                <Typography variant="h5" fontWeight="bold">
                                    Quelques mots sur moi
                                </Typography>
                                <Typography variant="subtitle1" lineHeight="normal" pb={1}>
                                    Ajoute une description amusante qui se démarque
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    multiline
                                    fullWidth={true}
                                    color="secondary"
                                    rows={3}
                                    id="bio"
                                    value={bio}
                                    onChange={(e) => {
                                        setBio(e.target.value)
                                        setCharacterCount(e.target.value.length)
                                        hasChanged();
                                    }}
                                    inputProps={{
                                        maxLength: 250,
                                    }}
                                    InputProps={{
                                        style: {borderRadius: '10px'},
                                        endAdornment: (
                                            <InputAdornment position="end"
                                                            sx={{position: 'absolute', bottom: 16, right: 16}}>
                                                <Typography variant="caption">{characterCount}/250</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>

                            {/*<Box>*/}
                            {/*    <Typography variant="h5">*/}
                            {/*        Mes centres d'intérêt*/}
                            {/*    </Typography>*/}
                            {/*    <Typography variant="h6">*/}
                            {/*        Indique tes centres d'intérêt*/}
                            {/*    </Typography>*/}
                            {/*    <List margin='0px' sx={{padding: '0px', margin: '0px', color: 'var(--inactive)'}}>*/}
                            {/*        <Typography variant="caption">*/}
                            {/*            Ajoutes les infos qui te mettent en valeurs*/}
                            {/*        </Typography>*/}
                            {/*        /!* TODO : mettre les emoji dans infoIcon ou modifier le code pour les insérés *!/*/}
                            {/*        <ListInfo infotheme='Caractéristique physique' infoIcon={''}/>*/}
                            {/*        <ListInfo infotheme='Profession ou études' infoIcon={''}/>*/}
                            {/*        <ListInfo infotheme='Emplacement' infoIcon={''}/>*/}
                            {/*        <ListInfo infotheme="Passion, centre d'intérêt" infoIcon={''}/>*/}
                            {/*        <ListInfo infotheme='Ce que tu cherche' infoIcon={''}/>*/}
                            {/*    </List>*/}
                            {/*</Box>*/}

                            <Box display="flex" flexDirection="column" gap={0.5}>
                                <Typography variant="h5" fontWeight="bold">
                                    Mes infos de base
                                </Typography>
                                <Typography variant="subtitle1" lineHeight="normal" pb={1}>
                                </Typography>

                                <Box display="flex" flexDirection="column" gap={1}>
                                    {/*Name*/}
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body1" flex="0 0 50%">
                                            Nom
                                        </Typography>
                                        <TextField flex="0 0 50%"
                                                   type="text"
                                                   inputProps={{maxLength: 12}}
                                                   id="name"
                                                   required
                                                   size="small"
                                                   color="secondary"
                                                   variant="outlined"
                                                   value={name}
                                                   onChange={(e) => {setName(e.target.value); hasChanged();}}
                                        />
                                    </Box>

                                    {/*Genre*/}
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body1" flex="0 0 50%">
                                            Genre
                                        </Typography>
                                        <Grid flex="0 0 50%" container direction="row" justifyContent="space-around" alignItems='center' p={0.5}
                                              sx={{borderRadius:'var(--stdBorderRadius)'}} >
                                            <Grid item xs>
                                                <Button variant={gender === "Male" ? 'activated' : 'sliderActionable'}
                                                        sx={{width: "100%"}} onClick={() => {setGender("Male"); hasChanged();}}>
                                                    <Typography variant="body2">
                                                        Homme
                                                    </Typography>
                                                </Button>
                                            </Grid>

                                            <Grid item xs>
                                                <Button
                                                    variant={gender === 'Female' ? 'activated' : 'sliderActionable'}
                                                    sx={{width: "100%"}} onClick={() => {setGender("Female"); hasChanged();}}>
                                                    <Typography variant="body2">
                                                        Femme
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                </Box>


                            <Box display="flex" flexDirection="column" gap={1} pt={2} pb={4}>
                                <Button variant="contained" color="primary" width="100%" onClick={handleSignout}>
                                    <Typography variant="body1">Déconnexion</Typography>
                                </Button>

                                <Button  variant="contained" color="primary" width="100%" onClick={() => setOpenModal(true)}>
                                    <Typography variant="body1">Supprimer mon compte</Typography>
                                </Button>
                            </Box>
                        </Stack>
                    </Grid>

                    <Grid item width={"100%"}>
                        <Navbar activeLink="acc"/>
                    </Grid>
                </Grid>

                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    container={() => document.getElementById('accModal')}
                >
                    <Box sx={{
                        position: 'absolute',
                        bottom: '2%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxWidth: '300px',
                        bgcolor: 'background.paper',
                        borderRadius: '15px',
                        textAlign: 'center',
                        boxShadow: 12,
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        {deleteError ?
                            <Typography variant="body2" sx={{p: 2}}>{deleteError}</Typography>
                            :
                            <>
                                <Button id="modal-modal-title" sx={{p: 2}} onClick={() => setOpenModal(false)} color="secondary">
                                    Annuler
                                </Button>
                                <Button id="modal-modal-description" sx={{p: 1}} onClick={handleDeleteAccount} color="secondary">
                                    <Typography color="#CC0000" variant="caption">Supprimer</Typography>
                                </Button>
                            </>
                        }
                    </Box>
                </Modal>
            </Box>
        </ThemeProvider>
    );
};

export default Account;