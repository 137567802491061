export const updateUser = async (id, userData, token) => {
    try {
        console.log(userData);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/${id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(userData),
        });

        if (!response.ok) {
            throw new Error("Error: Unable to update user account");
        }

        return await response.json();
    } catch (error) {
        throw new Error(error.message);
    }
};

export const deleteUser = async (userId, token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/delete`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({userId}),
        });

        if (!response.ok) {
            throw new Error("Impossible de supprimer votre compte. Veuillez contacter le support à l'adresse mail blissdatinggame@gmail.com");
        }

        return await response.json();
    } catch (error) {
       throw error;
    }
};