import './css/App.css';

import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import io from 'socket.io-client';
import {auth} from './firebase';
import { ampli } from './ampli';

import {insertNewUser, getLoginUserAccount} from './services/auth.service';
import {getMatchAndDispatchStore} from "./services/match.service";
import {fetchCountdown} from "./services/countdown.service";
import {loginUser, setLoading} from './redux/auth.slice';
import {updateMatch} from "./redux/match.slice";
import {setIsAlreadyInstalled, setIsIos, setSupportsPWA} from "./redux/pwa.slice";

import ProtectedRoute from './components/protectedRoute';
import JoinWrapperRoute from "./components/joinWrapperRoute";
import Join from './pages/join';
import Dashboard from './pages/dashboard';
import Matches from './pages/matches';
import Account from './pages/account';
import Register from "./pages/register";

import "@fontsource/raleway"; // Defaults to weight 400
import "@fontsource/raleway/400.css"; // Specify weight
import "@fontsource/raleway/400-italic.css";
import Loader from "./components/loader";
import MobileAuth from "./pages/mobileAuth";
import LandingPage from "./pages/landingPage";

import iconDashboard from './assets/icon_nav_dashboard.svg';
import iconMatches from './assets/icon_nav_matches.svg';
import iconAccount from './assets/icon_nav_account.svg';
import iconDashboardActive from './assets/icon_nav_dashboard_active.svg';
import iconMatchesActive from './assets/icon_nav_matches_active.svg';
import iconAccountActive from './assets/icon_nav_account_active.svg';

ampli.load({ environment: process.env.REACT_APP_AMPLITUDE_ENVIRONMENT });

const socket = io(`${process.env.REACT_APP_API_URL}`, {
  transports: ['websocket', 'polling', 'flashsocket'],
  autoConnect: false,
});

export const ROLES = {
  'User': 1,
  'NewUser': 0,
  'NewGuestUser': 2,
  'Guest': 3
}

function App() {
  const {user, role, token} = useSelector((state) => state.auth);
  const isLoading = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();

  const [socketConnected, setSocketConnected] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const loginRegisteredUser = (dbuser, token) => {
    socket.io.opts.query = {
      id: dbuser._id,
      token: token,
    };
    socket.connect();

    ampli.signIn({isRegistered: true});

    dispatch(loginUser({user: dbuser, token, role: ROLES.User}));
    dispatch(setLoading(false));
  }

  const loginNewUser = (dbuser, token) => {
    ampli.signIn({isRegistered: false});
    dispatch(loginUser({user: dbuser, token, role: ROLES.NewUser}));
    dispatch(setLoading(false));
  }

  const createNewUser = (token) => {
    insertNewUser(token).then((dbuser) => {
      ampli.signUp();
      dispatch(loginUser({user: dbuser, token, role: ROLES.NewUser}));
      dispatch(setLoading(false));
    }).catch((err) => {
      console.log("err", err);
      auth.signOut();
      dispatch(setLoading(false));
    })
  }

  // Check if user exist in the store on reload and connect socket if connected
  useEffect(() => {

    auth.onAuthStateChanged((authUser) => {
      console.log('onAuthStateChanged', authUser);
      if (authUser) {
        authUser.getIdToken().then((token) => {
          getLoginUserAccount(token).then((dbuser) => {
            if (dbuser && dbuser.isRegistered) {
              loginRegisteredUser(dbuser, token);
            } else if (dbuser && !dbuser.isRegistered) {
              loginNewUser(dbuser, token);
            } else {
              createNewUser(token);
            }
          });
        });
      } else {
        dispatch(setLoading(false));
      }
    });

    fetchCountdown();
    setInterval(fetchCountdown, 60000);

    // Socket
    socket.on('connect', () => {
      console.log("connected");

      socket.on('authentified', () => {
        console.log("authentified");
        setSocketConnected(true);
        socket.on('receive_chat_message', handleChatMessageReceived);
      });
    });

    socket.on('disconnect', () => {
      console.log("disconnected");
      socket.off('authentified');
      socket.off('receive_chat_message', handleChatMessageReceived);
      setSocketConnected(false);

    });

    return () => {
      socket.removeAllListeners('connect');
      socket.removeAllListeners('disconnect');
      socket.off('receive_chat_message', handleChatMessageReceived);

    };
  }, []);

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      console.log("we are being triggered :D", e);
      dispatch(setSupportsPWA(true));
      setDeferredPrompt(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    const appInstalledHandler = e => {
      console.log("installed");
      e.preventDefault();
      dispatch(setIsAlreadyInstalled(true));
      setDeferredPrompt(null);
    };
    window.addEventListener("appinstalled", appInstalledHandler);

    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      console.log(userAgent);
      return /iphone|ipad|ipod/.test( userAgent );
    }

    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    dispatch(setIsIos(isIos()));
    dispatch(setIsAlreadyInstalled(isInStandaloneMode()));
    if(isIos()) {
      dispatch(setSupportsPWA(true));
    }

    // return () => window.removeEventListener("transitionend", handler);
    return () => window.removeEventListener("beforeinstallprompt", handler);

  }, []);

  useEffect(() => {
    let deferredPrompt;

    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      deferredPrompt = event;
      // Show the install button or take any other action
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  // Matches list and messages
  useEffect(() => {
    if(socketConnected && user && role === ROLES.User) {
      getMatchAndDispatchStore(user._id, token);
    }
  }, [socketConnected])

  const handleChatMessageReceived = (data) => {
    const {matchId, lastMessage, lastMessageSender, isRead, timestamp} = data;
    dispatch(updateMatch({matchId, lastMessage, lastMessageSender, isRead, timestamp}));
  }

  return (
      <>
        {isLoading ? (
            <Loader />
        ) : (
            <div id='bodyWrapper'>
              <Router>
                <Routes>
                  <Route path='/' element={user && role === ROLES.User ? <Navigate to="/app/dashboard" replace/> :
                      user && role === ROLES.NewUser ? <Navigate to="/register" replace/> :
                          <LandingPage deferredPrompt={deferredPrompt} /> }/>

                  <Route element={<JoinWrapperRoute/>}>
                    <Route path="/join"
                           element={user && role === ROLES.User ? <Navigate to="/app/dashboard" replace/> :
                               user && role === ROLES.NewUser ? <Navigate to="/register" replace/> :
                                   <Join socket={socket} deferredPrompt={deferredPrompt}/>}/>
                  </Route>

                  <Route path='/auth' element={user && role === ROLES.User ? <Navigate to="/app/dashboard" replace/> :
                      role === ROLES.NewUser ? <Navigate to="/register" replace/> : <MobileAuth />}/>

                  <Route element={<ProtectedRoute allowedRoles={[ROLES.NewUser, ROLES.NewGuestUser]}/>}>
                    <Route path="/register" element={<Register socket={socket} />}/>
                  </Route>

                  <Route element={<ProtectedRoute allowedRoles={[ROLES.User, ROLES.Guest]}/>}>
                    <Route path='/app/dashboard'
                           element={<Dashboard socket={socket} socketConnected={socketConnected}/>}/>
                  </Route>

                  <Route element={<ProtectedRoute allowedRoles={[ROLES.User]}/>}>
                    <Route path='/app/matches' element={<Matches socket={socket}/>}/>
                    <Route path='/app/acc' element={<Account socket={socket}/>}/>
                  </Route>

                  <Route path='*' element={<Navigate to="/" replace/>}/>
                </Routes>
              </Router>
            </div>
        )}
      </>
  );
}


export default App;
