import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    matches: [],
};

const matchSlice = createSlice({
    name: "match",
    initialState: initialState,
    reducers: {
        setMatches: (state, {payload}) => {
            state.matches = payload;
        },
        removeMatch: (state, {payload}) => {
            const matchIndex = state.matches.findIndex(match => match.id === payload);
            if (matchIndex !== -1) {
                state.matches.splice(matchIndex, 1);
            }
        },
        updateMatch: (state, {payload}) => {
            // console.log(payload)
            const {matchId, lastMessage, lastMessageSender, isRead, timestamp} = payload;
            const matchToUpdate = state.matches.find(match => match.id === matchId);

            if (matchToUpdate) {
                matchToUpdate.lastMessage = lastMessage;
                matchToUpdate.lastMessageSender = lastMessageSender;
                matchToUpdate.isRead = isRead;
                matchToUpdate.timestamp = timestamp;
            }
        },
        setLastMessageToRead: (state, {payload}) => {
            const matchId = payload;
            const matchToUpdate = state.matches.find(match => match.id === matchId);

            if (matchToUpdate) {
                matchToUpdate.isRead = true;
            }
        },
    },
});

export const { setMatches, removeMatch, updateMatch, setLastMessageToRead } = matchSlice.actions;

export default matchSlice.reducer;
