import React, {useState} from "react";
import {Backdrop, Button, Grid, Typography} from "@mui/material";
import {ReactComponent as StartSvg} from "../../assets/star.svg";
import StyledAvatar from "../customAvatar";
import {useDispatch, useSelector} from "react-redux";
// import {auth, facebookProvider, googleProvider} from "../../firebase";
// import {updateGuest} from "../../services/auth.service";
// import {loginUser} from "../../redux/auth.slice";
// import {ROLES} from "../../App";
import {getMatchAndDispatchStore} from "../../services/match.service";
import {ROLES} from "../../App";
import {useNavigate} from "react-router-dom";

// Composant
const Match = ({socket, data: {winnerId}, players: {bachelor, contenders}, handleRedirect}) => {
    const {user, role, token} = useSelector((state) => state.auth);
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();

    const winner = contenders.find(contender => contender.id === winnerId);

    // GUEST V1
    // const handleGoogleSignupGuest = async () => {
    //     try {
    //         const result = await auth.signInWithPopup(googleProvider);
    //         const token = await result.user.getIdToken();
    //
    //         await signupGuest(result, token);
    //
    //     } catch(err) {
    //         // setMessageStatus(`Erreur: impossible de créer un compte ${err}`);
    //         await auth.signOut();
    //     }
    // }
    //
    // const handleFacebookSignUpGuest = async () => {
    //     try {
    //         const result = await auth.signInWithRedirect(facebookProvider);
    //         const token = await result.user.getIdToken();
    //
    //         await signupGuest(result, token);
    //     } catch(err) {
    //         // setMessageStatus("Erreur: impossible de créer un compte");
    //         await auth.signOut();
    //     }
    // }

    // const signupGuest = async (result, token) => {
    //     // check if he doesnt have already an acc.
    //     if(result.additionalUserInfo.isNewUser) {
    //         const dbuser = await updateGuest(user._id, {uid: result.user.uid, email: result.user.email}, token);
    //         if(dbuser) {
    //             socket.emit('update_to_account', {token});
    //             dispatch(loginUser({user: dbuser, token}));
    //         } else {
    //             // setMessageStatus('Erreur : Impossible de créer un compte');
    //             await result.user.delete();
    //             await auth.signOut();
    //         }
    //     } else { // already have an acc
    //         // setMessageStatus('Vous avez deja un compte associé à ce compte Google.');
    //         await auth.signOut();
    //     }
    // }

    const signupGuest = () => {
        navigate('/auth');
    }

    const handleClose = () => {
        setOpen(false);
        getMatchAndDispatchStore(user._id, token);
        handleRedirect();
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#C98BF2' }}
            open={open}
            onClick={handleClose}>

            <Grid container flexDirection="column" justifyContent="center" textAlign="center" gap={2} wrap="nowrap" width="90%">
                <Grid item>
                    <Typography variant="h5">Félicitation</Typography>
                    <Typography variant="h5">Tu as matché avec {user?.name === bachelor?.name ? winner?.name : bachelor?.name}</Typography>
                </Grid>

                <Grid item container flexDirection="row" justifyContent="center" position="relative">
                    <Grid item>
                        <StyledAvatar src={`${bachelor.url}`} sx={{ width: 100, height: 100, boxShadow: '0 0 25px 10px #F8F7FF' }} className="glow" />
                    </Grid>
                    <Grid item>
                        <StyledAvatar src={winner ? `${winner.url}` : ""} sx={{ width: 100, height: 100, boxShadow: '0 0 25px 10px #F8F7FF' }} className="glow"/>
                    </Grid>
                    <Grid item sx={{ position: "absolute", top: "40%" }}>
                        <StartSvg style={{width: "50px"}}/>
                    </Grid>
                </Grid>

                {/*{!(user && role === ROLES.User) && (*/}
                {/*    <Grid item>*/}
                {/*        <Typography variant="h6">Crée toi un compte si tu souhaites discuter avec ton match</Typography>*/}
                {/*        <Button variant="contained" color="secondary">*/}
                {/*            <Typography variant="body2" pl={1} pr={1} onClick={signupGuest}>Se creer un compte</Typography>*/}
                {/*        </Button>*/}
                {/*    </Grid>*/}
                {/*)}*/}

                <Grid item>
                    {/*{!(user && role === ROLES.User) && (<Typography variant="h6">Sinon</Typography>)}*/}
                    <Button variant="contained" color="secondary">
                        <Typography variant="body2" pl={1} pr={1}>Continuer de jouer</Typography>
                    </Button>
                </Grid>
            </Grid>
        </Backdrop>
    );
};

export default Match;