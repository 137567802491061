import Download from '../components/landing-page/Download';
import HowItWorks from '../components/landing-page/HowItWorks';
import Header from '../components/landing-page/Header';
import Benefits from "../components/landing-page/benefits/Benefits";
import {ThemeProvider} from "@mui/material";
import {theme} from "../theme";
import Reviews from "../components/landing-page/reviews/Reviews";
import Navbar from "../components/landing-page/Navbar";
import Footer from "../components/landing-page/footer/Footer";

const LandingPage = ({deferredPrompt}) => {
    return (
        <ThemeProvider theme={theme}>
            <Navbar />
            <Header />
            <HowItWorks />
            <Reviews />
            <Benefits />
            <Download />
            <Footer />
        </ThemeProvider>
    )
}

export default LandingPage