import { createSlice } from '@reduxjs/toolkit';

const pwaSlice = createSlice({
    name: 'pwa',
    initialState: {
        supportsPWA: false,
        promptInstall: null,
        isIos: false,
        isAlreadyInstalled: false
    },
    reducers: {
        setSupportsPWA: (state, action) => {
            state.supportsPWA = action.payload;
        },
        setPromptInstall: (state, action) => {
            state.promptInstall = action.payload;
        },
        setIsIos: (state, action) => {
            state.isIos = action.payload;
        },
        setIsAlreadyInstalled: (state, action) => {
            state.isAlreadyInstalled = action.payload;
        }
    },
});

export const { setSupportsPWA, setPromptInstall, setIsIos, setIsAlreadyInstalled } = pwaSlice.actions;
export default pwaSlice.reducer;
