import {useSelector } from 'react-redux';
import {Outlet, Navigate, useLocation} from 'react-router-dom';

const ProtectedRoute = ({ allowedRoles }) => {
    const location = useLocation();
    const user = useSelector((state) => state.auth.user);
    const role = useSelector((state) => state.auth.role);

    return (
        user && allowedRoles.includes(role) ? <Outlet />
            : user ? <Navigate to="/app/dashboard" state={{from: location}} replace />
                : <Navigate to="/join" state={{from: location}} replace />
    );
}

export default ProtectedRoute;