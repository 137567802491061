/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull react-app'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/blissdatinggame/Bliss/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/blissdatinggame/Bliss/implementation/react-app)
 */

import * as amplitude from '@amplitude/analytics-browser';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [configuration]
 * @property {BrowserClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'production'|'development'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} production
 * @property {string} development
 */
export const ApiKey = {
  production: 'bd5d2043e1b9f52dca436c78c8c9479f',
  development: '8e78d6d5c4a34c64d42c6ed6596c52af'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'react-app',
    versionId: '1a41510d-0226-4169-8c12-8f18a61abdaf'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-javascript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export class Identify {
  constructor(properties) {
    this.event_type = amplitude.Types.SpecialEventType.IDENTIFY;
    this.event_properties = properties;
  }
}

export class DownloadPwa {
  constructor(properties) {
    this.event_type = 'Download PWA';
    this.event_properties = properties;
  }
}

export class FinishSignUpProcess {
  constructor() {
    this.event_type = 'Finish Sign Up Process';
  }
}

export class GameFinished {
  constructor() {
    this.event_type = 'Game Finished';
  }
}

export class GameStart {
  constructor(properties) {
    this.event_type = 'Game Start';
    this.event_properties = properties;
  }
}

export class HomeButtonClicked {
  constructor(properties) {
    this.event_type = 'Home Button Clicked';
    this.event_properties = properties;
  }
}

export class InviteFriends {
  constructor(properties) {
    this.event_type = 'Invite Friends';
    this.event_properties = properties;
  }
}

export class JoinButtonClicked {
  constructor(properties) {
    this.event_type = 'Join Button Clicked';
    this.event_properties = properties;
  }
}

export class JoinFriend {
  constructor(properties) {
    this.event_type = 'Join Friend';
    this.event_properties = properties;
  }
}

export class PlayGame {
  constructor(properties) {
    this.event_type = 'Play Game';
    this.event_properties = properties;
  }
}

export class SignGuest {
  constructor() {
    this.event_type = 'Sign Guest';
  }
}

export class SignIn {
  constructor(properties) {
    this.event_type = 'Sign In';
    this.event_properties = properties;
  }
}

export class SignOut {
  constructor() {
    this.event_type = 'Sign Out';
  }
}

export class SignUp {
  constructor() {
    this.event_type = 'SignUp';
  }
}

export class StartSignUpProcess {
  constructor() {
    this.event_type = 'Start Sign Up Process';
  }
}

/**
 * @typedef PromiseResult
 * @type {object}
 * @property {Promise|undefined} promise
 */

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {BrowserClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
  }

  /**
   * @return {BrowserClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
   *
   * @return {PromiseResult}
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey;
    if (options?.client?.apiKey) {
      apiKey = options.client.apiKey;
    } else if (options?.environment) {
      apiKey = ApiKey[options.environment];
    }

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set or update that user's properties.
   *
   * @param {string|undefined} userId The user's id.
   * @param {Object} [properties] The user's properties.
   * @param {boolean} [properties.Gender] Property has no description in tracking plan.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  identify(userId, properties, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options, user_id: userId};
    }

    const ampIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        ampIdentify.set(key, value);
      }
    }
    return this.amplitude.identify(ampIdentify, options);
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  track(event, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.track(event, undefined, options);
  }

  /**
   * Download PWA
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/blissdatinggame/Bliss/events/main/latest/Download%20PWA)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.button] Property has no description in tracking plan.
   * @param {string} properties.platform Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  downloadPwa(properties, options) {
    return this.track(new DownloadPwa(properties), options);
  }

  /**
   * Finish Sign Up Process
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/blissdatinggame/Bliss/events/main/latest/Finish%20Sign%20Up%20Process)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  finishSignUpProcess(options) {
    return this.track(new FinishSignUpProcess(), options);
  }

  /**
   * Game Finished
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/blissdatinggame/Bliss/events/main/latest/Game%20Finished)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  gameFinished(options) {
    return this.track(new GameFinished(), options);
  }

  /**
   * Game Start
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/blissdatinggame/Bliss/events/main/latest/Game%20Start)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {number} properties.waitingTime Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  gameStart(properties, options) {
    return this.track(new GameStart(properties), options);
  }

  /**
   * Home Button Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/blissdatinggame/Bliss/events/main/latest/Home%20Button%20Clicked)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.button Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  homeButtonClicked(properties, options) {
    return this.track(new HomeButtonClicked(properties), options);
  }

  /**
   * Invite Friends
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/blissdatinggame/Bliss/events/main/latest/Invite%20Friends)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.groupId Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  inviteFriends(properties, options) {
    return this.track(new InviteFriends(properties), options);
  }

  /**
   * Join Button Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/blissdatinggame/Bliss/events/main/latest/Join%20Button%20Clicked)
   *
Event has no description in tracking plan.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.button] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  joinButtonClicked(properties, options) {
    return this.track(new JoinButtonClicked(properties), options);
  }

  /**
   * Join Friend
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/blissdatinggame/Bliss/events/main/latest/Join%20Friend)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.groupId Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  joinFriend(properties, options) {
    return this.track(new JoinFriend(properties), options);
  }

  /**
   * Play Game
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/blissdatinggame/Bliss/events/main/latest/Play%20Game)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.groupId Property has no description in tracking plan.
   * @param {boolean} properties.isInGroup Property has no description in tracking plan.
   * @param {boolean} properties.search Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  playGame(properties, options) {
    return this.track(new PlayGame(properties), options);
  }

  /**
   * Sign Guest
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/blissdatinggame/Bliss/events/main/latest/Sign%20Guest)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  signGuest(options) {
    return this.track(new SignGuest(), options);
  }

  /**
   * Sign In
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/blissdatinggame/Bliss/events/main/latest/Sign%20In)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} properties.isRegistered Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  signIn(properties, options) {
    return this.track(new SignIn(properties), options);
  }

  /**
   * Sign Out
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/blissdatinggame/Bliss/events/main/latest/Sign%20Out)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  signOut(options) {
    return this.track(new SignOut(), options);
  }

  /**
   * SignUp
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/blissdatinggame/Bliss/events/main/latest/SignUp)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  signUp(options) {
    return this.track(new SignUp(), options);
  }

  /**
   * Start Sign Up Process
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/blissdatinggame/Bliss/events/main/latest/Start%20Sign%20Up%20Process)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  startSignUpProcess(options) {
    return this.track(new StartSignUpProcess(), options);
  }
}

export const ampli = new Ampli();
