import {Carousel} from "react-responsive-carousel";
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

import StyledAvatar from "../components/customAvatar";
import React from "react";
import Compressor from "compressorjs";
import {uploadFile} from "./aws.service";
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";

export const formatGame = (game, i, user, matchurl) => {
    if(game.type === 'chain') {
        return (
            <Box key={i}>
                <Carousel showThumbs={false} showStatus={false} showArrows={false} swipeable={true}>
                    {game.data.map((answer, index) => (
                        <Box className={`game ${answer.bachelor === answer.contender ? 'chainSuccess' : 'chainFailure'}`}
                             key={index} display="flex" flexDirection="column" gap="10px">
                            <Typography flex="1 1 50%" variant="body2" component="div" color="primary">{<StyledAvatar src={`${game.bachelor === user._id ? user.pictures[0] : matchurl}`} sx={{ width: 15, height: 15 }} />} {answer.bachelor}</Typography>
                            <Typography flex="1 1 50%" variant="body2" component="div" color="primary">{<StyledAvatar src={`${game.bachelor !== user._id ? user.pictures[0] : matchurl}`} sx={{ width: 15, height: 15 }} />} {answer.contender ? answer.contender : "Pas de réponse"}</Typography>
                        </Box>
                    ))}
                </Carousel>
            </Box>
        )
    } else if(game.type === 'build') {
        return (
            <Box className="game" display="flex" flexDirection="column" gap="5px" key={i}>
                <Typography variant="body1" component="div" color="primary">{game.question}</Typography>
                <Typography variant="body2" color="primary" component="div" className="gameAnswer">{game.answer ? game.answer[0] : "Pas de réponse"}</Typography>
                <Typography variant="body2" color="primary" component="div" className="gameAnswer">{game.answer ? game.answer[1] : "Pas de réponse"}</Typography>
                <Typography variant="body2" color="primary" component="div" className="gameAnswer">{game.answer ? game.answer[2] : "Pas de réponse"}</Typography>
            </Box>
        )
    } else if(game.type === 'sentence') {
        return (
            <Box display="flex" flexDirection="column" key={i}>
                <div className={`message ${game.sender === user._id ? "me" : "other"}`}>
                    <Typography variant="body2" className="msgText">{game.question}</Typography>
                </div>
                {game.answer ?
                    <div className={`message ${game.sender !== user._id ? "me" : "other"}`} >
                        <Typography variant="body2" className="msgText">{game.answer}</Typography>
                    </div> : ''}

            </Box>
        )
    }

    return '';
}

export const getAgeFromBirth = (birth) => {
    const birthDate = new Date(birth);
    const currentDate = new Date();

    // Calculate the difference in milliseconds between the current date and birth date
    const ageDiffMs = currentDate - birthDate;

    // Calculate the age in years
    const ageDate = new Date(ageDiffMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const compressAndUpload = async (file) => {
    const compressedFile = await new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.8,
            maxWidth: 500,
            maxHeight: 500,
            success(result) {
                resolve(result);
            },
            error(err) {
                reject(err.message);
            },
        });
    });

    // get url
    return await uploadFile(compressedFile);
};