import React from "react";
import {Grid, Typography} from "@mui/material";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import Box from "@mui/material/Box";
import {FaInfoCircle} from 'react-icons/fa';

import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { frFR } from '@mui/x-date-pickers/locales';
import SubmitButton from "../mui/submitButton";

const RegisterBirth = ({birth, onBirthChange, onButtonClick}) => {

    const isDateValid = () => {
        return dayjs(birth).isValid();
    };

    const isAgeValid = () => {
        const currentDate = dayjs();
        const days = currentDate.diff(birth, "day");
        return days >= 365*18 && days < 365*100;
    };

    return (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
            <Grid container wrap='nowrap' direction="column" rowSpacing={3}>
                <Grid item>
                    <Typography variant="h4">Quelle est ta date de naissance ?</Typography>
                </Grid>
                <Grid item width="100%" textAlign="center">
                    <LocalizationProvider dateAdapter={AdapterDayjs} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText} width="100%">
                        <DesktopDatePicker color="secondary" value={dayjs(birth)} width="100%" onChange={onBirthChange} format="DD.MM.YYYY" openTo="day"
                                           slotProps={{ textField: { fullWidth: true, size: 'small', color: 'secondary' } }} minDate={dayjs("01/01/1923", "MM/DD/YYYY")} maxDate={dayjs().subtract(368*18, 'days')} />
                    </LocalizationProvider>
                </Grid>
            </Grid>

            <Grid container wrap='nowrap' direction="column" rowSpacing={3}>
                <Grid item>
                    <Box className="registerinfo">
                        <FaInfoCircle size={25} />
                        <Typography variant="caption">Ton âge sera affiché sur ton profil, pas ta date de naissance</Typography>
                    </Box>
                </Grid>
                <Grid item sx={{ width: '100%'}} mb={4}>
                    <SubmitButton text='Continuer' variant="contained" color="secondary" onButtonClick={onButtonClick} disabled={!isDateValid() || !isAgeValid()}/>
                </Grid>
            </Grid>
        </Box>

    );
}

export default RegisterBirth