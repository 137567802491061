import {Backdrop} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";

const GameExplanation = ({url}) => {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(false);
        }, 5000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <AnimatePresence>
        {
            open &&
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={open}
                onClick={() => setOpen(false)}>
                <Box className='profilModal' sx={{margin: "auto"}}>
                    <motion.div
                        initial={{x: '-100%'}}
                        animate={{x: 0}}
                        exit={{x: "100%"}}
                        transition={{type: "spring", stiffness: 450, damping: 40}}
                    >
                        <img src={url} alt="explication du jeu" className="gameExplanation"/>

                    </motion.div>
                </Box>
            </Backdrop>
        }
        </AnimatePresence>
    );
}

export default GameExplanation;