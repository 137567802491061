import React, {useEffect, useState} from "react";
import {Divider, Grid, Typography} from "@mui/material";
import Answer, {TYPES} from "../answer";
import GameHeader from "../ui/gameHeader";
import Box from "@mui/material/Box";
import AnswerInput from "../answerInput";
import GameExplanationImageContender from "../../assets/explanation_sentence_contender.svg";
import GameExplanation from "../gameExplanation";

const Sentence = ({socket, data: {question}, duration, players}) => {
    const [answer, setAnswer] = useState("");
    const [hasSubmit, setHasSubmit] = useState(false);
    const [responses, setResponses] = useState([]);
    const [isEliminating, setIsEliminating] = useState(false);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [isBlinking, setIsBlinking] = useState(false); // New state for blinking animation

    useEffect(() => {
        setAnswer("");

        socket.on('game_results', handleGameAnswers);
        socket.on('final_results', handleFinalAnswers);

        return () => {
            socket.off('game_results', handleGameAnswers);
            socket.off('final_results', handleFinalAnswers);
        }
    }, []);

    useEffect(() => {
        // Trigger blinking animation when selectedUserId is updated
        if(selectedUserIds.length === 2) {
            // console.log('set isblinkin true')
            setIsBlinking(true);
            const timer = setTimeout(() => {
                setIsBlinking(false);
                // console.log('set isblinkin false')
            }, 2000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [selectedUserIds]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        socket.emit("game_response", { answer });
        setHasSubmit(true);
    }

    const handleGameAnswers = (data) => {
        setHasSubmit(true);
        setResponses(data.responses)
        setIsEliminating(data.isEliminating)
    }

    const handleFinalAnswers = (data) => {
        // console.log(data);
        setSelectedUserIds(data.selectedUserIds);
    }

    return (
        <Box height="100%" display="flex" flexDirection="column" textAlign="center">
            <GameExplanation url={GameExplanationImageContender} />

            {!hasSubmit ?
                <GameHeader duration={duration} description={`Exprime toi ! ${duration/1000} secondes pour te faire entendre`} title="Réponse libre" players={players} />
                :
                <GameHeader duration={null} description={isEliminating ? `${players?.bachelor?.name} élimine quelqu'un ...` : "Attends que les prétendants répondent"} title="Élimination" players={players} />
            }

            <Grid container className="sentence" flexDirection='column' justifyContent="space-between" alignItems="center" width="95%" height="100%" margin="auto" wrap="nowrap">
                <Grid item>
                    <Answer url={players.bachelor.url} sentence={question} name={players.bachelor.name} type={TYPES.Color} />
                </Grid>

                {!hasSubmit ?
                    <Grid item container direction="row" alignItems="center" margin={2} color="white">
                        <Grid item width="100%">
                            <AnswerInput answer={answer} handleSubmit={handleSubmit}
                                         handleChange={(e) => setAnswer(e.target.value)}/>
                        </Grid>
                    </Grid>
                    :
                    <Grid item container className="eliminate" flexDirection='column' justifyContent="flex-start" alignItems="center" gap={2} width="95%" margin="auto" wrap='nowrap' height="100%">
                        <Grid container flexDirection={'column'} alignItems={"center"}>
                            <Divider>
                                <Typography textAlign="center">{isEliminating ? selectedUserIds.length > 0 ?  `${players?.bachelor?.name} à fait son choix !` : `${players?.bachelor?.name} choisit ses réponses préférees !` : "Attends que les prétendants répondent"}</Typography>
                            </Divider>
                        </Grid>

                        <Grid container direction={'column'} justifyContent={"space-around"} width={"100%"} height={'100%'} marginBottom={2}>
                            {responses.map((object, i) => {
                                const user = players.contenders.find(user => user.id === object.id);
                                return (<Grid item key={i} width="100%">
                                    <Answer url={user?.url} type={selectedUserIds.includes(object.id) ? TYPES.Active : selectedUserIds.length > 0 ? TYPES.Eliminate : TYPES.Normal}
                                            sentence={!object.answer ? isEliminating ? "Pas de réponse" : "" : object.answer} name={user?.name} isLoading={!object.answer && !isEliminating} isBlinking={isBlinking}/>
                                </Grid>);
                            })}
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Box>
    );
};

export default Sentence;