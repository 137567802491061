import React, {useState} from "react";
import {Backdrop, Button, Grid, Typography} from "@mui/material";
import {ROLES} from "../../App";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
// import {ROLES} from "../../App";
// import {useDispatch, useSelector} from "react-redux";
// import {auth, googleProvider} from "../../firebase";
// import {updateGuest} from "../../services/auth.service";
// import {loginUser} from "../../redux/auth.slice";

const Loser = ({socket, handleRedirect}) => {
    const {user, role} = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const [open, setOpen] = useState(true);

    // GUEST V1

    // const {user, role, token} = useSelector((state) => state.auth);
    // const dispatch = useDispatch();

    // const handleGoogleSignupGuest = async () => {
    //     try {
    //         const result = await auth.signInWithPopup(googleProvider);
    //         const token = await result.user.getIdToken();
    //
    //         await signupGuest(result, token);
    //
    //     } catch(err) {
    //         // setMessageStatus(`Erreur: impossible de créer un compte ${err}`);
    //         await auth.signOut();
    //     }
    // }
    //
    // const signupGuest = async (result, token) => {
    //     // check if he doesnt have already an acc.
    //     if(result.additionalUserInfo.isNewUser) {
    //         const dbuser = await updateGuest(user._id, {uid: result.user.uid, email: result.user.email}, token);
    //         if(dbuser) {
    //             socket.emit('update_to_account', {token});
    //             dispatch(loginUser({user: dbuser, token}));
    //         } else {
    //             // setMessageStatus('Erreur : Impossible de créer un compte');
    //             await result.user.delete();
    //             await auth.signOut();
    //         }
    //     } else { // already have an acc
    //         // setMessageStatus('Vous avez deja un compte associé à ce compte Google.');
    //         await auth.signOut();
    //     }
    // }

    const signupGuest = () => {
        navigate('/auth');
    }

    const handleClose = () => {
        setOpen(false);
        handleRedirect();
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#333' }}
            open={open}
            onClick={handleClose}>

            <Grid container className="loser" flexDirection="column" justifyContent="center" height='100%' padding="0 5%" wrap="nowrap" spacing={2} textAlign="center">
                <Grid item>
                    <Typography variant="h5">Tu as été éliminé ...</Typography>
                    <Typography variant="h6">Mais ce n'est pas fini !</Typography>
                    <Typography variant="h6">Retente ta chance</Typography>
                </Grid>

                <Grid item>
                    <Button variant="contained" color="primary">
                        <Typography variant="body2" sx={{color: "white"}} pl={1} pr={1}>Rejouer</Typography>
                    </Button>
                </Grid>

                {/*{!(user && role === ROLES.User) && (*/}
                {/*    <>*/}
                {/*        <Grid item>*/}
                {/*            <Typography variant="h6">Sinon</Typography>*/}
                {/*        </Grid>*/}
                {/*        <Grid item>*/}
                {/*            <Button variant="contained" color="primary">*/}
                {/*                <Typography variant="body2" pl={1} pr={1} onClick={signupGuest}>Se creer un compte</Typography>*/}
                {/*            </Button>*/}
                {/*        </Grid>*/}
                {/*    </>*/}
                {/*)}*/}
            </Grid>
        </Backdrop>
    );
};

export default Loser;