import {setCountdown} from "../redux/countdown.slice";
import {store} from '../redux/store';

export const fetchCountdown = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/countdown`)
        .then(async (response) => {
            const data = await response.json();
            store.dispatch(setCountdown({ targetDate: data.targetDate, isOn: data.countdown > 0 }));
        })
        .catch((err) => {
            // console.log(err);
        });
};